import React, { useContext } from "react";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  InstapaperShareButton,
  InstapaperIcon,
} from "react-share";
import "../style/ShareNews.scss";
import axios from "axios";
import { apiURL } from "../utils";
import { PWAContext } from "../utils/PwaAppContext";


const ShareNews = ({
  shareUrl,
  title,
  date,
  time,
  webinarId,
  trainingId,
  articleId,
  blogId,
  govt_circulars_id
}) => {
  let shareTitle = title;
  if (date && time) {
    shareTitle += ` - Date: ${date}, Time: ${time}`;
  } else if (date) {
    shareTitle += ` - Date: ${date}`;
  }

  // Append "?source=shared" to track PWA or browser
  const modifiedShareUrl = `${shareUrl}?source=shared`;
  const PWA = useContext(PWAContext);

  const handleShareCount = async () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      webinar_id: webinarId || trainingId || "",
      article_id: articleId || "",
      blog_id: blogId || "",
      govt_circulars_id: govt_circulars_id || "",
    };
    try {
      const response = await axios.post(apiURL("share/add_share"), body, {
        headers: headers,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    // (!PWA.isPWA && PWA) ? (
      <div className="newsShare">
        <div className="Demo__some-network float-right sharebutton">
          <TwitterShareButton url={modifiedShareUrl} title={title} onClick={handleShareCount}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </div>

        <div className="Demo__some-network float-right sharebutton">
          <LinkedinShareButton url={modifiedShareUrl} onClick={handleShareCount}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </div>

        <div className="Demo__some-network float-right sharebutton">
          <TelegramShareButton url={modifiedShareUrl} title={title} onClick={handleShareCount}>
            <TelegramIcon size={32} round />
          </TelegramShareButton>
        </div>

        <div className="Demo__some-network float-right sharebutton">
          <FacebookShareButton url={modifiedShareUrl} quote={title} onClick={handleShareCount}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </div>

        <div className="Demo__some-network float-right sharebutton">
          <WhatsappShareButton url={modifiedShareUrl} title={shareTitle} separator=":: " onClick={handleShareCount}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
      </div>
    // ) : null
  );
};

export default ShareNews;
