import React from "react";
import "../style/YearlyData.css";

function YearlyData() {
  const data = [
    { year: "2016-17", value: 95, color: "#1e90ff", valueT: 3466, colorT: "#87ceeb" },
    { year: "2017-18", value: 57, color: "#1e90ff", valueT: 3284, colorT: "#87ceeb" },
    { year: "2018-19", value: 108, color: "#1e90ff", valueT: 5879, colorT: "#87ceeb" },
    { year: "2019-20", value: 62, color: "#1e90ff", valueT: 3169, colorT: "#87ceeb" },
    { year: "2022-23", value: 25, color: "#1e90ff", valueT: 1243, colorT: "#87ceeb" },
    { year: "2023-24", value: 87, color: "#1e90ff", valueT: 4852, colorT: "#87ceeb" },
    { year: "2024-25", value: 88, color: "#1e90ff", valueT: 4949, colorT: "#87ceeb" },
  ];

  // Find max values for normalization
  const maxValue = Math.max(...data.map((item) => item.value));
  const maxValueT = Math.max(...data.map((item) => item.valueT));

  return (
    <div className="table-container">
      <table>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              {/* Small value bar (Left) - Align Right */}
              <td className="imp">
                <div
                  className="bar small"
                  style={{
                    width: `${(item.value / maxValue) * 100}%`,
                    backgroundColor: item.color,
                  }}
                >
                  {item.value}
                </div>
              </td>

              {/* Year Column (Narrow Center) */}
              <td className="year">{item.year}</td>

              {/* Large value bar (Right) - Align Left */}
              <td>
                <div
                  className="bar large"
                  style={{
                    width: `${(item.valueT / maxValueT) * 100}%`,
                    backgroundColor: item.colorT,
                  }}
                >
                  {item.valueT}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default YearlyData;
