import React, { Fragment, useState, useEffect } from "react";
import { Container, Row, Col } from "bootstrap-4-react";
import "../../style/TrForTeacher.scss";
import { toJpeg } from "html-to-image";
import Alert from "@material-ui/lab/Alert";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@material-ui/core";
import { AiOutlineDownload } from "@react-icons/all-files/ai/AiOutlineDownload";
import axios from "axios";
import { apiURL, getID, isLogin } from "../../utils";
import card from "../../images/TR. FOR TEACHERS VISITING CARD-01.jpg";
import { useHistory } from "react-router-dom";
import ProfileForm from "./ProfileForm";

const TRTeacherForm = () => {
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    contact: "",
    email: "",

    role: "",
    city: "",
    schcollname: "",
    pincode: "",
    board: "",
    grades: [],
    subjects: [],
  });
  const [address, setAddress] = useState("");
  const [contactError, setContactError] = useState(""); // State for contact number validation error
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    const body = {
      fldi_id: getID(),
    };
    const header = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    try {
      const response = await axios.post(apiURL("teacher/get_profile"), body, {
        headers: header,
      });
      const result = response.data.result;

      const firstname =
        result.fldv_first_name !== ""
          ? result.fldv_first_name
          : result.fldv_name;
      const lastname =
        result.fldv_last_name !== ""
          ? result.fldv_last_name
          : result.fldv_surname;
      const contact = result.fldv_contact;
      const email = result.fldv_email;
      const role = result.fldv_faculty_status;

      const city = result.fldi_city;
      const schcollname = result.fldv_school_college;
      const pincode = result.fldv_pincode;
      const board = result.fldv_board_medium;

      const grades = result.fldv_grades_taught
        ? result.fldv_grades_taught.split(",").map((grade) => grade.trim())
        : [];

      const subjects = result.fldv_subjects_taught
        ? result.fldv_subjects_taught.split(",").map((sub) => sub.trim())
        : [];
      const userProfile = {
        firstname: firstname,
        lastname: lastname,
        contact: contact,
        email: email,

        role: role,
        city: city,
        schcollname: schcollname,
        pincode: pincode,
        board: board,
        grades: grades,
        subjects: subjects,
      };
      setUser(userProfile);
    } catch (error) {
      console.error("Failed to fetch user profile:", error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (updatedUser) => {
    setOpen(false);
    if (updatedUser) {
      setUser(updatedUser);
      if (validateFields(updatedUser)) {
        downloadCard(updatedUser);
      }
    } else {
      fetchUserProfile();
    }
  };

  const validatePhoneNumber = (contact) => {
    const phonePattern = /^[0-9]{10,28}$/;
    if (!contact.match(phonePattern)) {
      setContactError("Please enter a valid phone number.");
      return false;
    }
    setContactError("");
    return true;
  };

  const validateFields = (user) => {
    return (
      user.firstname !== "" &&
      user.lastname !== "" &&
      user.contact !== "" &&
      user.email !== "" &&
      user.role !== "" &&
      user.city !== "" &&
      user.pincode !== "" &&
      user.board !== "" &&
      user.grades.length > 0 &&
      user.subjects.length > 0
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    if (name === "contact") {
      validatePhoneNumber(value); // Validate phone number on change
    }
  };

  const handleKeyPress = (e) => {
    const allowedChars = /^[0-9\-\(\)\+\.\/\,\*\#\s]*$/;
    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  };

  const downloadCard = (userToDownload = user) => {
    const firstName = userToDownload.firstname;
    const lastName = userToDownload.lastname;
    const contact = userToDownload.contact;

    // Validate fields before proceeding
    if (!firstName || !lastName) {
      setSnackBarMessage("Please fill all mandatory fields to download the card");
      setSeverityColor("error");
      setShowSnackbar(true);
      return;
    }

    if (!contact || !validatePhoneNumber(contact)) {
      setSnackBarMessage("Please enter a valid 10-digit phone number.");
      setSeverityColor("error");
      setShowSnackbar(true);
      return;
    }

    const cardElement = document.getElementById("player-wrapper");
    toJpeg(cardElement, { quality: 1.0 })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "visiting-card.jpg";
        link.href = dataUrl;
        link.click();

        if (!isLogin()) {
          userInfo();
          // Clear user fields if not logged in
          setUser({
            firstname: "",
            lastname: "",
            contact: "",
            email: "",
            role: "",
            city: "",
            schoolname: "",
            pincode: "",
            board: "",
            grades: [],
            subjects: [],
          });
          setAddress("");
        } else {
          userInfo(); // Call user info API if logged in
        }
      })
      .catch((error) => {
        setSnackBarMessage("Error generating the visiting card.");
        setSeverityColor("error");
        setShowSnackbar(true);
      });
  };

  const userInfo = async () => {
    const userData = JSON.parse(localStorage.getItem("user_detail"));

    const body = isLogin()
      ? {
        user_id: getID(),
        body: {
          firstName: user.firstname,
          lastName: user.lastname,
          contact: user.contact,
          email: user.email,
          address: address,
        },
        isRegistered: userData.fldv_contact == user.contact ? true : false,
      }
      : {
        body: {
          firstName: user.firstname,
          lastName: user.lastname,
          contact: user.contact,
          email: user.email,
          address: address,
        },
        isRegistered: userData?.fldv_contact == user.contact ? true : false,
      };

    const header = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    try {
      const response = await axios.post(
        apiURL("teacher/download_visiting_card"),
        body,
        {
          headers: header,
        }
      );
      console.log(response);
    } catch (error) {
      console.error("Failed to fetch user profile:", error);
    }
  };

  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severityColor, setSeverityColor] = useState("");
  const snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  // Check if mandatory fields are filled
  const isDownloadEnabled = user.firstname && user.lastname && user.contact;

  return (
    <Fragment>
      <Container className="card-form">
        <h2>Dear Teacher,</h2>
        <p>You can now download your personalized visiting card.</p>
        <Row>
          <Col md={5} sm={12}>
            <form className="user-form">
              <p>Please Enter/Review your details.</p>
              <p style={{ color: "red" }}>
                * Fields marked with an asterisk are mandatory to download the card.
              </p>

              <div className="form-group">
                <label>First Name: <span style={{ color: "red" }}>*</span></label>
                <br />
                <input
                  type="text"
                  name="firstname"
                  value={user.firstname}
                  onChange={handleChange}
                  required
                  maxLength={15}
                />
              </div>

              <div className="form-group">
                <label>Last Name: <span style={{ color: "red" }}>*</span></label>
                <br />
                <input
                  type="text"
                  name="lastname"
                  value={user.lastname}
                  onChange={handleChange}
                  required
                  maxLength={15}
                />
              </div>

              <div className="form-group">
                <label>Telephone: <span style={{ color: "red" }}>*</span></label>
                <br />
                <input
                  type="tel"
                  name="contact"
                  value={user.contact}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  maxLength={10}
                  required
                />
                <br />
                {contactError && (
                  <span style={{ color: "red" }}>{contactError}</span>
                )}
              </div>

              <div className="form-group">
                <label>Email:</label>
                <br />
                <input
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={handleChange}
                  maxLength={55}
                  required
                />
              </div>

              <div className="form-group">
                <label>Address:</label>
                <br />
                <input
                  type="text"
                  name="address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  maxLength={110}
                />
              </div>
            </form>
          </Col>
          <Col md={7} sm={12}>
            <div className="maincard">
              <div className="player-wrapper" id="player-wrapper">
                <img
                  src={card}
                  className="react-player"
                  controls="true"
                  width="100%"
                  height="100%"
                />
                <div id="card-preview" className="card-preview">
                  <div className="card-content">
                    <h3>Tr. {`${user.firstname} ${user.lastname}`}</h3>
                    <div className="user-input">
                      {address !== "" && (
                        <div className="address">
                          <p>
                            <b>Address: </b><span style={{ overflowWrap: "break-word" }}>{address}</span>
                          </p>
                        </div>
                      )}
                      <div className="tel">
                        <p>
                          <b>Tel:</b> <span>{user.contact}</span>
                        </p>
                      </div>
                      <div className="email">
                        <p>
                          <b>Email:</b> <span style={{ overflowWrap: "break-word" }}>{user.email}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="action">
              <Button
                onClick={() => downloadCard()}
                style={{ marginTop: "20px", cursor: 'pointer' }}
                disabled={contactError || !isDownloadEnabled} // Disable button if there is an error or mandatory fields are not filled
              >
                Download
                <span>
                  <AiOutlineDownload />
                </span>
              </Button>
            </div>
          </Col>
        </Row>
      </Container>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={showSnackbar === true}
        autoHideDuration={6000}
        onClose={() => snackBarClose()}
      >
        <Alert
          onClose={() => snackBarClose()}
          severity={severityColor}
          elevation={6}
          variant="filled"
        >
          {snackBarMessage}
        </Alert>
      </Snackbar>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Please Complete Your Profile
        </DialogTitle>
        <DialogContent>
          <ProfileForm userData={user} open={open} onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default TRTeacherForm;
