import React, { Component, forwardRef } from "react";
import { Navbar, Nav, Dropdown, Collapse } from "bootstrap-4-react";
import "../style/Navmenu.scss";
import { NavLink } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../utils";
import { setMenus as initialMenus } from "../common/navbarData";
import { PWAContext } from "../utils/PwaAppContext";
import successStories from "../images/Icon_Success stories .png"
import articles from "../images/Icon_Articles.png"
import govtCirculars from "../images/Icon_Govt circular GR.png"
import trainingsIcon from "../images/Icon_Trainings.png"
import newsIcon from "../images/Icon_News.png"
import interviewIcon from "../images/Icon_Inteviews.png"
import faqIcon from "../images/Icon_FAQ.png"
import BookRecommendationsIcon from "../images/Icon_Books Recommendations.png";
import blogsIcon from "../images/Icon_Blogs.png"
import homeIcon from "../images/Icon_Home.png"
import academicEssayIcon from "../images/Icon_Academic Essays.png"
import selfDevelopmentIcon from "../images/Icon_Self Development.png"
import pastTrainingIcon from "../images/Icon_Past Training.png"
import upcomingEventIcon from "../images/Icon_Upcoming Events.png"
import overviewIcon from "../images/Icon_OverView.png"
import webinarIcon from "../images/Icon_Webinar.png"
import aboutUsIcon from "../images/Icon_About Us.png"
import trforTeacherIcon from "../images/Icon_TR.png"
import videoIcon from "../images/Icon_Videos.png"
import contactusIcon from "../images/Icon-contact-us.png"
class Navmenu extends Component {
  _isMounted = false;
  previousMenus = []; // To store the previous state of menus

  constructor(props) {
    super(props);
    const storedMenus = localStorage.getItem("menuData");
    this.state = {
      showTexts: false,
      setMenus: storedMenus ? JSON.parse(storedMenus) : initialMenus,
      isNavbarOpen: false
    };
    this.previousMenus = this.state.setMenus; // Initialize previousMenus
  }

  getMenuIcon = (menuName) => {
    switch (menuName.toLowerCase()) {
      case "home":
        return <img src={homeIcon} alt="homeIcon" className="navmenu-main-icons-image"/>;
      case "trainings":
        return <img src={trainingsIcon} alt="trainingsIcon" className="navmenu-main-icons-image"/>;
      case "about us":
        return <img src={aboutUsIcon} alt="aboutUsIcon" className="navmenu-main-icons-image"/>;
      case "articles":
        return <img src={articles} alt="articles" className="navmenu-main-icons-image"/>;
      case "govt. circulars/gr":
        return <img src={govtCirculars} alt="govtCirculars" className="navmenu-main-icons-image"/>;
      case "faq":
        return <img src={faqIcon} alt="faqIcon" className="navmenu-main-icons-image"/>;
      case "tr. for teacher":
        return  <img src={trforTeacherIcon} alt="trforTeacherIcon" className="navmenu-main-icons-image"/>;
      case "contact us":
        return <img src={contactusIcon} alt="contactusIcon" className="navmenu-main-icons-image"/>
      case "overview":
        return <img src={overviewIcon} alt="overviewIcon" className="navmenu-sub-icons-image" />;
      case "upcoming events":
        return <img src={upcomingEventIcon} alt="upcomingEventIcon" className="navmenu-sub-icons-image"/>;
      case "past trainings":
        return <img src={pastTrainingIcon} alt="pastTrainingIcon" className="navmenu-sub-icons-image"/>;
      case "past webinars":
        return <img src={webinarIcon} alt="webinarIcon" className="navmenu-sub-icons-image"/>;
      case "videos":
        return <img src={videoIcon} alt="videoIcon" className="navmenu-sub-icons-image"/>;
      case "blogs":
        return <img src={blogsIcon} alt="blogsIcon" className="navmenu-sub-icons-image"/>;
      case "academic essays":
        return <img src={academicEssayIcon} alt="academicEssayIcon" className="navmenu-sub-icons-image"/>;
      case "success stories":
        return <img src={successStories} alt="successStories" className="navmenu-sub-icons-image"/>;
      case "interviews":
        return <img src={interviewIcon} alt="interviewIcon" className="navmenu-sub-icons-image"/>;
      case "book recommendations":
        return <img src={BookRecommendationsIcon} alt="BookRecommendationsIcon" className="navmenu-sub-icons-image"/>;
      case "self development":
        return <img src={selfDevelopmentIcon} alt="selfDevelopmentIcon" className="navmenu-sub-icons-image"/>;
      case "news":
        return <img src={newsIcon} alt="newsIcon" className="navmenu-sub-icons-image"/>;
      case "mh state board":
        return <img src={govtCirculars} alt="govtCirculars" className="navmenu-sub-icons-image" />;
      case "central board":
        return <img src={govtCirculars} alt="govtCirculars" className="navmenu-sub-icons-image" />;
      default:
        return <img src={trainingsIcon} alt="default" className="navmenu-main-icons-image"/>;
    }
  };

  showText = () => {
    if (window.scrollY <= 700) {
      this.setState({ showTexts: true });
    } else {
      this.setState({ showTexts: false });
    }
  };

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("scroll", this.showText);
    this.loadMenusFromLocalStorage();
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("scroll", this.showText);
    document.body.classList.remove("no-scroll");
  }
  
  // Handle toggling of the navbar
  toggleNavbar = () => {
    this.setState(
      (prevState) => ({ isNavbarOpen: !prevState.isNavbarOpen }),
      () => {
        if (this.state.isNavbarOpen) {
          document.body.classList.add("no-scroll");
        } else {
          document.body.classList.remove("no-scroll");
        }
      }
    );
  };

  loadMenusFromLocalStorage = () => {
    const storedMenus = localStorage.getItem("menuData");
    if (storedMenus) {
      this.setState({ setMenus: JSON.parse(storedMenus) });
    } else {
      localStorage.setItem("menuData", JSON.stringify(initialMenus));
    }
  };

  getMenus = () => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("menu/get_all_menu_test"), { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const newMenus = resultData.result;
            // Compare previous and current menus
            if (JSON.stringify(this.previousMenus) !== JSON.stringify(newMenus)) {
              // Update state and local storage if there is a change
              this.setState({ setMenus: newMenus }, () => {
                localStorage.setItem("menuData", JSON.stringify(newMenus));
              });
              // Update previousMenus to the new state
              this.previousMenus = newMenus;
            }
          }
        }
      })
      .catch((error) => {
        console.log("Error fetching menus:", error);
      });
  };

  render() {
    const { setMenus } = this.state;
    return (
      <PWAContext.Consumer>
        {({ isPWA }) => (
       <React.Fragment>
          {!isPWA?
          <Navbar
            key={JSON.stringify(setMenus)} // Force re-render by changing key
            className={
              this.state.showTexts
                ? "navbar-light NavBox showText"
                : "sticky-top navbar-light NavBox"
            }
            expand="lg"
          >
            <Navbar.Toggler target="#navbarSupportedContent" onClick={this.toggleNavbar}/>
            <Collapse navbar id="navbarSupportedContent">
              <Navbar.Nav mx="auto" className="NavbarBox">
              {/* <div className="navbar-close-icon-mobile"><CloseIcon/></div> */}
                {setMenus.map((arrMenu, index) => (
                  <span key={index}>
                    {arrMenu.arrChilds.length > 0 ? (
                      <Nav.Item dropdown>
                        <Nav.Link className="nav-link linkBox" dropdownToggle>
                        <span className="menu-icon">
                            {this.getMenuIcon(arrMenu.fldv_name)}
                          </span>
                          {arrMenu.fldv_name}
                        </Nav.Link>
                        <Dropdown.Menu>
                          {arrMenu.arrChilds.map((arrChildMenu, childIndex) => (
                            <span key={childIndex}>
                              <NavLink
                                activeClassName="active_class"
                                exact
                                className="nav-link"
                                to={arrChildMenu.fldv_to_path}
                              >
                                <span className="menu-icon">
                                {this.getMenuIcon(arrChildMenu.fldv_name)}
                                </span>
                                {arrChildMenu.fldv_name}
                              </NavLink>
                              <Dropdown.Divider />
                            </span>
                          ))}
                        </Dropdown.Menu>
                      </Nav.Item>
                    ) : (
                      <Nav.Item>
                        <NavLink
                          activeClassName="active_class"
                          exact
                          className="nav-link linkBox"
                          to={arrMenu.fldv_to_path}
                        >
                           <span className="menu-icon">
                            {this.getMenuIcon(arrMenu.fldv_name)}
                          </span>
                          {arrMenu.fldv_name}
                        </NavLink>
                      </Nav.Item>
                    )}
                  </span>
                ))}
              </Navbar.Nav>
            </Collapse>
          </Navbar>
          :""}
       </React.Fragment>
       )}
      </PWAContext.Consumer>
    );
  }
}

// Forward the ref to the Navmenu component
export default forwardRef((props, ref) => <Navmenu {...props} ref={ref} />);