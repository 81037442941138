import React, { Component } from "react";
/*import ReactDom from 'react-dom';*/
import Navmenu from "../components/Navmenu";
import Appbar from "../components/Appbar";
import Footer from "../components/Footer";
import { Row, Col } from "bootstrap-4-react";
import { withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import "../style/Sign.scss";
import SignIn from "./SignIn";
import Signup from "./Signup";
/*import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';*/
import { apiURL, login } from "../utils";
import axios from "axios";
/*import { Link, useHistory } from "react-router-dom";
import { Redirect } from 'react-router';*/
import { Snackbar, Container, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
/*import { Grid } from "@material-ui/core";
import { faTumblrSquare } from '@fortawesome/free-brands-svg-icons';*/
import signlogoImage from "../images/navdisha.png";
import ForgotPassword from "./ForgotPassword";
import { Tooltip, Typography } from "@material-ui/core";
import mainImage from "../images/SignIn-SignUp.png";
const styles = (theme) => ({
  bigIndicator: {
    height: 0,
  },
});

class Sign extends Component {
  //state = { value: 0 }
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      //value: (this.props.location)?this.props.location.state.value:0,
      value: 0,
      showTexts: true,
      googleResponse: [],
      facebookResponse: [],
      socialResponse: [],

      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      snackBarTimer: "",
    };
  }

  componentDidMount() {
    // const element = document.getElementById("signIn");

    // element.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'start',
    //   inline: 'nearest'
    // });

    // if (window.innerWidth < "900") {
    //   //alert('< 900'+window.innerWidth);
    //   window.scrollTo(90, 90);
    // } else {
    //   //alert('> 900'+window.innerWidth);
    //   window.scrollTo(90, 90);
    // }
    //window.scrollTo(420, 420);
    this._isMounted = true;
    // window.addEventListener("scroll", this.signNav);
    /* if(this.props.pageProp)
      {
      console.log('root props '+JSON.stringify(this.props.pageProp.history.location.pathname)); 
      }*/

    if (
      this.props.location &&
      this.props.location.state &&
      !window.location.search
    ) {
      this.setState({
        value: this.props.location.state.value,
        /*showSnackbar: this.props.location.state.showSnackbar,
          snackBarMessage: this.props.location.state.snackBarMessage,
          severityColor: this.props.location.state.severityColor,
          snackBarTimer: this.props.location.state.snackBarTimer*/
      });
    }

    if (window.location.search) {
      //alert(true)
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const authEmail = urlParams.get("auth");
      //alert(atob(decodeURIComponent(authEmail)));
      //const decodeAuthEmail = atob(decodeURIComponent(authEmail));
      this.authenticateEmail(authEmail);
    }
  }

  authenticateEmail = (email) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      email: email,
    };
    axios
      .post(apiURL("authenticate"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          // this.setState({
          //   snackBarMessage:
          //     "Email address verified successfully. Please login",
          //   showSnackbar: true,
          //   severityColor: "success",
          //   snackBarTimer: 3000,
          //   value: 0,
          // });
        }
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  signNav = () => {
    //console.log(window.scrollY)
    if (window.scrollY >= 0) {
      //alert('if '+window.scrollY)
      this.setState({ showTexts: false });
    } else {
      //alert('else '+window.scrollY)
      this.setState({ showTexts: true });
    }
  };

  handleChange = (event, value) => {
    //alert(value);
    this.setState({ value });
  };

  responseGoogle = (response) => {
    const body = {
      name: response.profileObj.name,
      email: response.profileObj.email,
      source: "gmail",
    };
    return this.loginWithSocial(body);
  };

  responseFacebook = (response) => {
    //console.log('facebook response', response);
    const body = {
      name: response.name,
      email: response.email,
      source: "facebook",
    };
    return this.loginWithSocial(body);
  };

  loginWithSocial = (socialData) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = { email: socialData.email };
    axios
      .post(apiURL("register/check_email"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.setState({
            emailError: true,
            emailErrorMsg: resultData.message,
          });
          localStorage.setItem(
            "user_detail",
            JSON.stringify(resultData.result)
          );
          localStorage.setItem("login_status", true);
          if (this.props.pageProp) {
            //alert(JSON.stringify(this.props.pageProp.history.location.pathname));
            const path = this.props.pageProp.history.location.pathname;
            this.props.pageProp.history.push(path);
            if (this.props.checkJoinNow) {
              // this prop send from webinar as per require
              this.props.checkJoinNow(true);
            }
          } else {
            this.props.history.push("/");
          }
        } else {
          this.setState({ value: 1, socialResponse: socialData });
        }
      });
  };

  getSignUpState = () => {
    this.setState({ value: 0 });
    this.setState({
      snackBarMessage: "Successfully registered",
      showSnackbar: true,
      severityColor: "success",
      snackBarTimer: 10000,
    });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  // this is for webinar check login to join now button
  checkJoinNow = () => {
    this.props.checkJoinNow(true);
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <React.Fragment>
        <Appbar />
        <div
          className={
            this.state.showTexts ? " signNav " : " sticky-top sign-light "
          }
        >
          <Navmenu />
        </div>
        <section
          id="signIn"
          className="logInBox" style={{ marginTop: "80px" }} >
          <Container maxWidth="lg">
            <Row>
              <Col lg="6" md="12" sm="12">
                <div className="mainImage">
                  <img src={mainImage} alt="sign" />
                </div>
              </Col>
              <Col lg="6" md="12" sm="12" className="mx-auto mainBox">
                {/* <div className="buttonTabs">
                  <Button
                    className={`button ${this.state.value === 0 ? "active" : ""
                      }`}
                    onClick={(e) => this.handleChange(e, 0)}
                  >
                    Sign In
                  </Button>
                  <Button
                    className={`button ${this.state.value === 1 ? "active" : ""
                      }`}
                    onClick={(e) => this.handleChange(e, 1)}
                  >
                    Sign Up
                  </Button>
                </div> */}
                {this.state.value === 0 && (
                  <Row>
                    <Col sm="8" className="formMainBox"
                    >
                      <span className="heading">
                        Login To Access Your Account
                      </span>
                      <span style={{ marginTop: "-15px", color: "grey" }}>(Existing User)</span>
                      <SignIn
                        propsData={
                          this.props.pageProp ? this.props.pageProp : this.props
                        }
                        checkJoinNow={(e) => this.checkJoinNow(e)}
                        handleSignup={(e) => this.handleChange(e, 1)}
                      />

                      {/* <div className="row">
                      <div className="mt-4 col-md-5 col-sm-12 ">
                          <GoogleLogin
                            clientId="621490980909-pnnsqoultsfqe26auv5784f2k7n9n5pq.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                            //clientId="492751910892-q3phq1490s26bl9vi1q7im234e1f32h0.apps.googleusercontent.com" //CLIENTID NOT CREATED YET
                            buttonText="LOGIN WITH GOOGLE"
                            onSuccess={this.responseGoogle}
                            onFailure={this.responseGoogle}
                            className="googleBox"
                            cookiePolicy={'single_host_origin'}
                          /> 
                      </div>
    
                    <div className="mt-3 pt-md-3 pt-0 col-md-2 col-sm-12 text-center orBox" >
                         <h3>OR</h3>
                    </div>

                    <div className="mt-md-4 mt-2 col-md-5 col-sm-12 ml-md-auto ">
                         <FacebookLogin
                          //appId="622648051623600" //APP ID NOT CREATED YET
                          appId="3181292698636480"
                          fields="name,email,picture"
                          callback={this.responseFacebook}
                        />   
                    </div>
                </div> */}

                      {/* start forgot password form */}
                      {/* <ForgotPassword /> */}
                      {/* end forgot password form */}
                    </Col>
                  </Row>
                )}
                {this.state.value === 1 && (
                  <Row>
                    <Col sm="8" className="formMainBox">
                      <span className="heading">
                        Create Your Account To Join NavDisha
                      </span>
                      <Signup
                        getSocialResponse={this.state.socialResponse}
                        setState={(e) => this.getSignUpState(e)}
                        propsData={this.props}
                        handleSignin={(e) => this.handleChange(e, 0)}
                      />
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={this.state.showSnackbar === true}
              autoHideDuration={this.state.snackBarTimer}
              onClose={this.snackBarClose}
            >
              <Alert
                onClose={this.snackBarClose}
                severity={this.state.severityColor}
                elevation={6}
                variant="filled"
              >
                {this.state.snackBarMessage}
              </Alert>
            </Snackbar>
          </Container>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Sign);
