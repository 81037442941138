import React, { Fragment, useRef, useEffect, useState } from "react";
import "../../style/TrForTeacher.scss";
import { Helmet } from "react-helmet";
import Appbar from "../Appbar";
import Footer from "../Footer";
import TRForTeacher from "../../images/Tr-For-Teacher.jpg";
import { Button } from "@material-ui/core";
import { Container, Row, Col } from "bootstrap-4-react";
import ReactPlayer from "react-player/lazy";
import TRTeacherForm from "./TRTeacherForm";
import { AiOutlineDownload } from "@react-icons/all-files/ai/AiOutlineDownload";
import Meta from "../../meta/Meta";
const TrForTeacher = () => {
  const formRef = useRef(null);
  const buttonRef = useRef(null);
  const [isButtonFixed, setIsButtonFixed] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  const handleScroll = () => {
    const formPosition = formRef.current.getBoundingClientRect();
    const buttonPosition = buttonRef.current.getBoundingClientRect();

    if (formPosition.top <= window.innerHeight && formPosition.bottom >= 0) {
      setIsButtonVisible(false);
    } else {
      setIsButtonVisible(true);
    }

    if (buttonPosition.top <= window.innerHeight / 2) {
      setIsButtonFixed(true);
    } else {
      setIsButtonFixed(false);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <Meta tableName={"menu"} objectId={16} forPage={"main_page"} />
      <Helmet>
        <title>TR For Teacher</title>
        <meta charSet="utf-8" />
        <link rel="icon" href="/navdisha-favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        <meta name="theme-color" content="#000000" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta name="robots" content="TrForTeacher" />
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="TrForTeacher" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
      </Helmet>
      <Appbar />
      <img src={TRForTeacher} alt="Sample" className="full-width-image"  style={window.innerWidth < 600 ? { marginTop: "74px" } : { marginTop: "80px" }} />

      <section className="information">
        <Container className="container">
          <div
            className={`visitingCard ${isButtonFixed ? "fixed" : ""} ${isButtonVisible ? "" : "hidden"
              }`}
            ref={buttonRef}
          >
            <Button onClick={scrollToForm}>
              <span>
                <AiOutlineDownload />
              </span>
              <span>
                Download Your
                <br /> Visiting Card
              </span>
            </Button>
          </div>
          <Row className="first-row">
            <Col md={5} sm={12}>
              <p>
                The 'Tr. For Teacher' initiative, conceptualized by Navneet,
                aims to bestow upon teachers the same level of recognition and
                respect accorded to professionals in other noble professions.
                Just as doctors (Dr.) or captains (Capt.) carry titles denoting
                their professions, Navneet believes that teachers, who play a
                crucial role in shaping the future of society, deserve similar
                acknowledgment.
              </p>
              <p>
                Teachers, often the unsung heroes of our society, dedicate
                themselves selflessly to nurturing young minds and instilling
                values that transcend the classroom. Despite their invaluable
                contributions, they often go unnoticed and underappreciated.
                Recognizing this disparity, Navneet launched the 'Tr. For
                Teacher' initiative to shine a spotlight on the tireless efforts
                of educators and to advocate for their rightful recognition.
              </p>
            </Col>
            <Col md={7} sm={12}>
              <div className="player-wrapper">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/RKZgtHb7ZLc?mute=1"
                  frameborder="0"
                  allow="encrypted-media; fullscreen"
                  allowfullscreen
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
                {/* <ReactPlayer
                  url={
                    "https://www.youtube.com/watch?v=RKZgtHb7ZLc&ab_channel=NavneetEducationLtd"
                  }
                  className="react-player"
                  controls
                  width="100%"
                  height="100%"
                  config={{
                    file: {
                      attributes: { autoPlay: false, muted: false },
                    },
                  }}
                /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div className="form" ref={formRef}>
        <TRTeacherForm />
      </div>
      <Footer />
    </Fragment>
  );
};

export default TrForTeacher;
