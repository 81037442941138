import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card } from "bootstrap-4-react";
import { IconButton, Button, Container } from "@material-ui/core";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import Meta from "../meta/Meta";
import "../style/HomeEvents.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Moment from "moment";
import ArticleImageLoader from "../components/Article/ArticleImageLoader";

const ArticlesHome = (props) => {
  const [page, setPage] = useState(1);
  const [per, setPer] = useState(window.innerWidth <= 1000 ? 1 : 2);
  const [categoryID, setCategoryID] = useState(null);
  const [noData, setNoData] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedItem, setSelectedItem] = useState(0);
  const [articleData, setArticleData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);

  const history = useHistory();
  const carouselRef = useRef(null);
  let _isMounted = false;

  useEffect(() => {
    _isMounted = true;
    setIsMobile(window.innerWidth <= 1000);
    articleListData();

    return () => {
      _isMounted = false;
    };
  }, []);

  //   useEffect(() => {
  //     if (prevProps.articleData !== articleData) {
  //       setArticleData(articleData);
  //     }
  //   }, [articleData]);

  const articleListData = () => {
    setLoading(true);

    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      id: "",
      category_id: "",
      // user_id:getID()
    };
    axios
      .post(apiURL("article/get_article_all"), body, { headers: headers })
      .then((response) => {
        if (_isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            setArticleData(resultData.result.slice(0, 4));
            setNoData("");
            setLoading(false);
          } else {
            setNoData("");
            setArticleData([]);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const redirectArticle = (e, id, title, category, category_id) => {
    if (!isLogin()) {
      localStorage.setItem('redirectAfterLogin',"/article/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),{ category });
      history.replace("/sign");
    } else {
      history.push(
        "/article/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
        { category }
      );
    }
  };

  const handleAddBookMark = (e, id) => {
    e.stopPropagation();
    if (!isLogin()) {
      history.replace("/sign");
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "articles",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const index = articleData.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...articleData];
              updatedEventsList[index].is_bookmark = true;
              setArticleData(updatedEventsList);
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRemoveBookMark = (e, id) => {
    e.stopPropagation();
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "articles",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = articleData.map((event) => {
            if (event.fldi_id === id) {
              return {
                ...event,
                is_bookmark: false,
              };
            }
            return event;
          });
          setArticleData(updatedEventsList);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrev = () => {
    setSelectedItem((prevItem) => Math.max(prevItem - 1, 0));
  };


  const handleNext = () => {
    const totalPages = Math.ceil(articleData.length / per);
    setSelectedItem((prevItem) => Math.min(prevItem + 1, totalPages - 1));
  };

  const displayData = isMobile
    ? articleData.slice(selectedItem, selectedItem + 1)
    : articleData.slice(selectedItem * 2, selectedItem * 2 + 2);
  const singleData = displayData.length === 1;

  return (
    <React.Fragment>
      {articleData.length !== 0 && (
        <div>
          {/* <section className="circularSection"> */}

          <section className="articleGrHome">
            <Container maxWidth="lg" className="container">
              <Carousel
                ref={carouselRef}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={selectedItem}
                onChange={(index) => setSelectedItem(index)}
              >
                <Row className="articleGrMainHome">
                  {displayData.map((arrArticle, index) => (
                    <Col
                      key={index}
                      col="12 col-lg-6 col-xl-4"
                      className="mx-auto custom-col-spacing mt-4"
                    >
                      <Card
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          redirectArticle(
                            e,
                            arrArticle.fldi_id,
                            arrArticle.fldv_title,
                            arrArticle.fldv_category_name,
                            arrArticle.fldi_category_id
                          )
                        }
                      >
                        <Card.Body>
                          <figure>
                            <div className="d-block mainImage">
                              <ArticleImageLoader
                                imageUrl={arrArticle.fldv_image_url}
                                altText={"img"}
                              />
                              {/* <img
                                src={arrArticle.fldv_image_url}
                                className="img-fluid"
                                alt={arrArticle.fldv_title}
                              /> */}
                            </div>
                          </figure>
                          <div className="content">
                            <div className="topic">
                              <h4>{arrArticle.fldv_title}</h4>
                              <div className="date">
                              <h4>{arrArticle.fldv_author_name}</h4>
                            </div>
                            </div>
                            <div className="date">
                              <h4>
                                {arrArticle.fldv_changed_date ? Moment(arrArticle.fldv_changed_date).format("D MMM, yyyy") : ""}
                              </h4>
                            </div>
                          </div>
                        </Card.Body>

                        <div className="action">
                          <div>
                            <Button
                              variant="contained"
                              size="small"
                              className="readmore"
                              onClick={(e) =>
                                redirectArticle(
                                  e,
                                  arrArticle.fldi_id,
                                  arrArticle.fldv_title,
                                  arrArticle.fldv_category_name,
                                  arrArticle.fldi_category_id
                                )
                              }
                            >
                              Read More
                            </Button>
                          </div>
                          {/* <div>
                            {arrArticle.is_bookmark ? (
                              <IconButton
                                style={{ color: "orange" }}
                                className="bookmark"
                                onClick={(e) =>
                                  handleRemoveBookMark(e, arrArticle.fldi_id)
                                }
                              >
                                <Bookmark />
                              </IconButton>
                            ) : (
                              <IconButton
                                className="bookmark"
                                onClick={(e) =>
                                  handleAddBookMark(e, arrArticle.fldi_id)
                                }
                              >
                                <BookmarkBorder />
                              </IconButton>
                            )}
                          </div> */}
                        </div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Carousel>
              <IconButton
                onClick={handlePrev}
                className={singleData ? "singlePrevButton" : "prevButton"}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={handleNext}
                className={singleData ? "singleNextButton" : "nextButton"}
              >
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </section>
          {/* </Container>
        </section> */}
        </div>
      )}
    </React.Fragment>
  );
};

export default ArticlesHome;