import React, { Component } from 'react';
import Navmenu from '../components/Navmenu';
import Appbar from '../components/Appbar';
import "../style/training.scss";
import "../style/pastraining.scss";
import { Container, Row, Col, Card } from 'bootstrap-4-react';
import Footer from '../components/Footer';
import Button from '@material-ui/core/Button';
import { withStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FeedBackForm from './FeedBackForm';
import VideocamIcon from '@material-ui/icons/Videocam';

import { apiURL, isLogin, getName } from '../utils';
import axios from 'axios';
import Moment from 'moment';
import SanitizedHTML from 'react-sanitized-html';
import Sign from "../login/Sign";
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Meta from "../meta/Meta";

const styles = theme => ({
   dialogPaper: {
      minHeight: '90vh',
      maxHeight: '90vh',
      borderRadius: '25px',
      maxWidth: '42.75rem',
   },
});

class pastTrainingSchedule extends Component {
   _isMounted = false;
   constructor(props) {
      super(props);
      this.state = {
         open: false,
         pastTrainingData: [],
         showComponent: false,
         showSnackbar: false,
         snackBarMessage: '',
         severityColor: '',
         snackBarTimer: ''
      }
      this._sign = React.createRef();
      this.pastTrainingSchedule = this.pastTrainingSchedule.bind(this);
   }


   componentDidMount() {
      this._isMounted = true;
      this.pastTrainingSchedule();
      window.scrollTo(0, 0);
      // alert(this.props.match.params.trainingId);
   }

   componentWillUnmount() {
      this._isMounted = false;
   }

   handleClickOpen = () => {
      if (!isLogin()) {
         this.setState({ showComponent: true })
      }
      else {
         this.setState({ open: true })
      }
   }

   handleClose = (e) => {
      if (e === true) {
         this.setState({
            showSnackbar: true,
            snackBarMessage: 'Thank you for getting in touch! We appreciate you contacting us....',
            severityColor: 'success',
            snackBarTimer: '3000',
            //open: false 
         });
      }
      this.setState({ open: false });
   }


   snackBarClose = (event, reason) => {
      if (reason === 'clickaway') {
         return;
      }
      this.setState({ showSnackbar: false });
   }

   checkJoinNow = () => {
      this.pastTrainingSchedule();
      this.setState({ showComponent: false })
   }


   pastTrainingSchedule = () => {
      const headers = {
         'content-type': 'application/json',
         'Accept': 'application/json'
      }
      axios.get(apiURL('training_schedule/get_training_schedule_by_id/' + this.props.match.params.trainingId), { headers: headers })
         .then(response => {
            const resultData = response.data;
            if (resultData.status === true || resultData.status === 1) {
               this.setState({ pastTrainingData: resultData.result });
            }

         })
         .catch(error => {
            console.log(error)
         })
   }

   render() {
      const { classes } = this.props;
      return (
         <React.Fragment>
            <Meta tableName={"training_schedule"} objectId={this.props.match.params.trainingId} forPage={"inner_page"} />
            {this.state.showComponent ? <Sign ref={this._sign} pageProp={this.props} checkJoinNow={(e) => this.checkJoinNow(e)} /> :
               <div>
                  <Appbar />
                  <div className="sticky-top sign-light">
                     <Navmenu />
                  </div>
                  <section className="BannerBox TrainingBox"  >
                     <div className="sixteen-nine">
                        <div className="content text-center text-white invisible">
                           <h2>Past Trainings Schedule</h2>
                           <p>PAST  TRAININGS SCHEDULE TO FEED YOUR BRAIN</p>
                        </div>
                     </div>
                  </section>
                  <section className="careerMain">
                     <Container>
                        <Row className="Blogbox careerBox scheduleBox">
                           <Col col="sm-12 md-12" className="mx-sm-auto">
                              <Card className="h-auto">
                                 <div className="clearfix">
                                    <div className="headBox float-left pt-5 w-75">
                                       <h2>{this.state.pastTrainingData.fldv_title}</h2>
                                       <h5>{Moment(this.state.pastTrainingData.fldv_select_date).format('ddd')}, {Moment(this.state.pastTrainingData.fldv_from_time).format('LT')} to {Moment(this.state.pastTrainingData.fldv_to_time).format('LT')}</h5>
                                    </div>
                                    <div className="float-right  dateBox text-center">
                                       <h3>{Moment(this.state.pastTrainingData.fldv_select_date).format('D')}</h3>
                                       <h5>{Moment(this.state.pastTrainingData.fldv_select_date).format('MMMM')}</h5>
                                       <h6>{Moment(this.state.pastTrainingData.fldv_select_date).format('YYYY')}</h6>
                                    </div>
                                 </div>
                                 <div className="d-flex justify-content-between flex-wrap">
                                    <div className="SpeakerBox col-md-6 col-sm-12 pl-0">
                                       <div className="Speaker">
                                          <img src={this.state.pastTrainingData.speaker_profile_image} className="img-fluid float-md-left mr-3" alt={this.state.pastTrainingData.speaker_name} />
                                          <h5>Speaker :</h5>
                                          <h4>{this.state.pastTrainingData.speaker_name}</h4>
                                       </div>
                                    </div>
                                    <div>
                                       <a href={this.state.pastTrainingData.fldv_video_url} rel="noopener noreferrer" target="_blank"><Button className="CheckButton text-center float-right marginBox">
                                          <VideocamIcon />
                                          Watch Now
                                       </Button>
                                       </a>
                                    </div>
                                 </div>
                                 <div>
                                    <img src={this.state.pastTrainingData.fldv_image_url} className="img-fluid w-100" alt={this.state.pastTrainingData.fldv_title} />
                                 </div>
                                 <Card.Body>
                                    <Card.Text><SanitizedHTML allowedAttributes={false} allowedTags={false} html={this.state.pastTrainingData.fldv_description} /></Card.Text>
                                 </Card.Body>
                              </Card>
                              <Button className="CheckButton checkBtnSchedule text-center" onClick={this.handleClickOpen}>
                                 Share Feedback
                              </Button>
                              <Dialog
                                 open={this.state.open}
                                 onClose={() =>
                                    this.handleClose()}
                                 aria-labelledby="alert-dialog-title"
                                 aria-describedby="alert-dialog-description"
                                 classes={{ paper: classes.dialogPaper }}
                              >
                                 {/* 
                     <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
                     */}
                                 <h2 className="text-center  headBoxRegister text-uppercase">SHARE FEEDBACK</h2>
                                 <DialogContent>
                                    <FeedBackForm trainingId={this.props.match.params.trainingId} title={this.state.pastTrainingData.fldv_title} formClose={(e) => this.handleClose(e)} />
                                 </DialogContent>
                                 {/* 
                     <DialogActions>
                        <Button onClick={() => this.handleClose()} color="primary">
                        Disagree
                        </Button>
                        <Button onClick={() => this.handleClose()} color="primary" autoFocus>
                        Agree
                        </Button>
                     </DialogActions>
                     */}
                              </Dialog>
                           </Col>
                        </Row>
                     </Container>
                  </section>
                  <div className="customFooter">
                     <Footer />
                  </div>
                  <Snackbar open={this.state.showSnackbar === true} autoHideDuration={this.state.snackBarTimer} onClose={this.snackBarClose}>
                     <Alert onClose={this.snackBarClose} severity={this.state.severityColor} elevation={6} variant="filled">
                        {this.state.snackBarMessage}
                     </Alert>
                  </Snackbar>
               </div>
            }
         </React.Fragment>
      );
   }
}
export default withStyles(styles)(pastTrainingSchedule);