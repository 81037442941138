import React, { useState, useEffect, useRef } from "react";
import "../style/OtherWeb.scss";
import { Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import { Container, Grid } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import axios from "axios";
import Moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import Meta from "../meta/Meta";
import { ArrowLeft, BookmarkBorder } from "@material-ui/icons";
import { Bookmark } from "@material-ui/icons";
import logoNew from "../images/navdisha.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArticleImageLoader from "../components/Article/ArticleImageLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
}));

const PastTrainingHome = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const carouselRef = useRef();
  const [pastTrainingsList, setPastTrainingsList] = useState([]);
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [page, setPage] = useState(1);
  const [per, setPer] = useState(window.innerWidth <= 1000 ? 1 : 2);
  const [selectedItem, setSelectedItem] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState("");

  useEffect(() => {
    setIsMobile(window.innerWidth <= 1000);
    getPastTrainings();

    return () => {
      // Cleanup if needed
    };
  }, []);

  const handleClickOpen = (e, id, title) => {
    if (!isLogin()) {
      setShowComponent(true);
    } else {
      setOpen(true);
      setId(id);
      setTitle(title);
    }
  };

  const handleReadMore = (e, id, title) => {
    e.preventDefault();
    addView(id)
    if (!isLogin()) {
      localStorage.setItem('redirectAfterLogin', "/training/" + id + "/" + title);
      history.push("/sign");
    } else {
      history.push("/training/" + id + "/" + title);
    }
  };

  const addView = (id) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      webinar_id:id,
      user_id: getID(),
    };
    axios
      .post(apiURL("views/add_views"), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          console.log(resultData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPastTrainings = () => {
    setLoading(true);
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      status: 1,
      event_type: "PE",
      user_id: getID(),
      event_mode: "Physical",
    };
    axios
      .post(
        apiURL("webinar/events?per_page=" + 4 + "&page=" + 1),
        body,
        {
          headers: headers,
        }
      )
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setPastTrainingsList(resultData.result);
        } else {
          setNoData("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLogin()) {
      setShowComponent(true);
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "past_training",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const updatedEventsList = pastTrainingsList.map((event) =>
              event.fldi_id === id ? { ...event, is_bookmark: true } : event
            );
            setPastTrainingsList(updatedEventsList);
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRemoveBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_training",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = pastTrainingsList.map((event) =>
            event.fldi_id === id ? { ...event, is_bookmark: false } : event
          );
          setPastTrainingsList(updatedEventsList);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrev = () => {
    setSelectedItem((prevItem) => Math.max(prevItem - 1, 0));
  };

  const handleNext = () => {
    const totalPages = Math.ceil(pastTrainingsList.length / per);
    setSelectedItem((prevItem) => Math.min(prevItem + 1, totalPages - 1));
  };

  const truncateTitle = (title, wordLimit) => {
    const words = title.split(" ");
    return words.length > wordLimit
      ? `${words.slice(0, wordLimit).join(" ")}...`
      : title;
  };

  // Slice data based on selectedItem and per
  const displayData = isMobile
    ? pastTrainingsList.slice(selectedItem, selectedItem + 1)
    : pastTrainingsList.slice(selectedItem * 2, selectedItem * 2 + 2);
  const singleData = displayData.length === 1;

  return (
    <React.Fragment>
      {pastTrainingsList.length !== 0 ? (
        <div>
          <section className="mainEveSection">
            <h2 className="text-center">Past Trainings</h2>
            <Container maxWidth="lg" className="container">
              <Carousel
                ref={carouselRef}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={selectedItem}
                onChange={(index) => setSelectedItem(index)}
              >
                <Row className="trainWebHome position-relative">
                  {" "}
                  {displayData.map((arrList) => (
                    <Col
                      col="12 col-lg-6 col-xl-4"
                      className="mx-auto custom-col-spacing"
                    >
                      <Card>
                        <Link>
                          <Card.Body
                            onClick={(e) =>
                              handleReadMore(
                                e,
                                arrList.fldi_id,
                                arrList.fldv_title
                                  .replace(/ /g, "-")
                                  .toLowerCase()
                              )
                            }
                          >
                            <div className="position-relative">
                              {/* <div className="SpeakerBox text-center"> */}
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <div className="Speaker">
                                    <figure>
                                      <div className="d-block imgBox">
                                        <ArticleImageLoader
                                          imageUrl={arrList.fldv_image_url}
                                          altText={"img"}
                                        />
                                        {/* <img
                                          src={
                                            arrList.fldv_image_url === ""
                                              ? logoNew
                                              : arrList.fldv_image_url
                                          }
                                          className="img-fluid"
                                          alt="img"
                                        /> */}
                                      </div>
                                      <p>{truncateTitle(arrList.fldv_speaker_name, 3)}</p>
                                    </figure>
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                  <table className="table">
                                    <tr>
                                      <th colSpan="2" className="topic">
                                        {arrList.fldv_title}
                                      </th>
                                    </tr>

                                    <tbody>
                                      <tr>
                                        <th>Date:</th>
                                        <td>
                                          {Moment(
                                            arrList.fldv_select_date
                                          ).format("ddd, D MMM, YYYY")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Time:</th>
                                        <td>
                                          {Moment(arrList.fldt_time).format(
                                            "LT"
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Duration:</th>
                                        <td>{arrList.fldv_duration} hrs</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  {/* <div> */}
                                  <div className="action">
                                    <div className="readmoreContainer">
                                      <Button
                                        variant="contained"
                                        size="small"
                                        className="readmore"
                                        onClick={(e) =>
                                          handleReadMore(
                                            e,
                                            arrList.fldi_id,
                                            arrList.fldv_title
                                              .replace(/ /g, "-")
                                              .toLowerCase()
                                          )
                                        }
                                      >
                                        Read More
                                      </Button>
                                    </div>
                                    <div className="bookmarkContainer">
                                      {arrList.is_bookmark ? (
                                        <IconButton
                                          style={{ color: "orange" }}
                                          className="bookmark"
                                          onClick={(e) =>
                                            handleRemoveBookMark(
                                              e,
                                              arrList.fldi_id
                                            )
                                          }
                                        >
                                          <Bookmark />
                                        </IconButton>
                                      ) : (
                                        <IconButton
                                          className="bookmark"
                                          onClick={(e) =>
                                            handleAddBookMark(
                                              e,
                                              arrList.fldi_id
                                            )
                                          }
                                        >
                                          <BookmarkBorder />
                                        </IconButton>
                                      )}
                                    </div>
                                  </div>
                                </Grid>
                                {/* </div> */}
                              </Grid>
                            </div>
                          </Card.Body>
                        </Link>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Carousel>
              <IconButton
                onClick={handlePrev}
                className={singleData ? "singlePrevButton" : "prevButton"}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={handleNext}
                className={singleData ? "singleNextButton" : "nextButton"}
              >
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </section>
        </div>
      ):""}
    </React.Fragment>
  );
};

export default PastTrainingHome;