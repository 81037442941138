import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Card } from "bootstrap-4-react";
import { IconButton, Button, Container } from "@material-ui/core";
import { Bookmark, BookmarkBorder } from "@material-ui/icons";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import Meta from "../meta/Meta";
import "../style/WhatsNew.scss";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Moment from "moment";
import ArticleImageLoader from "../components/Article/ArticleImageLoader";

const GrCircularHome = (props) => {
  const [state, setState] = useState({
    page: 1,
    per: window.innerWidth <= 1000 ? 1 : 2,
    newsData: [],
    categoryID: null,
    noData: "",
    selectedTab: 0,
    loading: true,
    selectedItem: 0,
    isMobile: false,
  });

  const history = useHistory();
  const carouselRef = useRef(null);
  let _isMounted = false;

  useEffect(() => {
    _isMounted = true;
    setState((prevState) => ({ ...prevState, isMobile: window.innerWidth <= 1000 }));
    circularListData();

    return () => {
      _isMounted = false;
    };
  }, []);

  const circularListData = () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const { per, page, newsData } = state;
    console.log(props);
    const headers = {
      "Content-type": "application/json",
    };
    const body = { id: "", user_id: getID(), board: "" };
    axios
      .post(apiURL("news/get_all_news?per_page=" + 4 + "&page=" + 1), body, {
        headers: headers,
      })
      .then((response) => {
        if (_isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const updatedNewsList = [...newsData, ...resultData.result];
            setState((prevState) => ({
              ...prevState,
              newsData: updatedNewsList,
              scrolling: false,
              loading: false,
            }));
          } else {
            setState((prevState) => ({ ...prevState, noData: "", loading: false, scrolling: false }));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const redirectArticle = (e, id, title, category) => {
    addView(id)
    if (!isLogin()) {
      localStorage.setItem('redirectAfterLogin', "/news/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),{ category });
      history.replace("/sign");
    } else {
      history.push(
        "/news/" + id + "/" + title.replace(/ /g, "-").toLowerCase(),
        { category }
      );
    }
  };

  const addView = (id) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      govt_circulars_id:id,
      user_id: getID(),
    };
    axios
      .post(apiURL("views/add_views"), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          console.log(resultData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddBookMark = (e, id) => {
    e.stopPropagation();
    if (!isLogin()) {
      history.replace("/sign");
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "news",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const index = state.newsData.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...state.newsData];
              updatedEventsList[index].is_bookmark = true;
              setState((prevState) => ({
                ...prevState,
                newsData: updatedEventsList,
              }));
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleRemoveBookMark = (e, id) => {
    e.stopPropagation();
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "news",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = state.newsData.map((event) => {
            if (event.fldi_id === id) {
              return {
                ...event,
                is_bookmark: false,
              };
            }
            return event;
          });
          setState((prevState) => ({
            ...prevState,
            newsData: updatedEventsList,
          }));
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrev = () => {
    setState((prevState) => ({
      ...prevState,
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  const handleNext = () => {
    const { selectedItem, per } = state;
    const totalPages = Math.ceil(state.newsData.length / per);
    setState((prevState) => ({
      ...prevState,
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };

  const { id } = props;
  const { newsData, selectedItem, isMobile } = state;

  const displayData = isMobile
    ? newsData.slice(selectedItem, selectedItem + 1)
    : newsData.slice(selectedItem * 2, selectedItem * 2 + 2);
  const singleData = displayData.length === 1;

  return (
    <React.Fragment>
      <Meta tableName={"menu"} objectId={5} forPage={"main_page"} />

      {newsData.length !== 0 && (
        <div>
          {/* <section className="circularSection"> */}
          {/* <Container> */}
          <section className="articleGrHome">
            <Container maxWidth="lg" className="container">
              <Carousel
                ref={carouselRef}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={selectedItem}
                onChange={(index) => setState((prevState) => ({ ...prevState, selectedItem: index }))}
              >
                <Row className="articleGrMainHome">
                  {displayData.map((arrNews, index) => {
                    return (
                      <Col
                        key={index}
                        col="12 col-lg-6 col-xl-4"
                        className="mx-auto custom-col-spacing mt-4"
                      >
                        <Card
                          style={{ cursor: "pointer" }}
                          onClick={(e) =>
                            redirectArticle(
                              e,
                              arrNews.fldi_id,
                              arrNews.fldv_title,
                              props.category_name
                            )
                          }
                        >
                          <Card.Body>
                            <figure>
                              <div className="d-block mainImage">
                                <ArticleImageLoader
                                  imageUrl={arrNews.fldv_image_url}
                                  alt={arrNews.fldv_title}
                                />
                                {/* <img
                                  src={arrNews.fldv_image_url}
                                  className="img-fluid"
                                  alt={arrNews.fldv_title}
                                /> */}
                              </div>
                            </figure>
                            <div className="content">
                              <div className="topic">
                                <h4>{arrNews.fldv_title}</h4>
                              </div>
                              <div className="date">
                                <h4>
                                  {" "}
                                  {Moment(arrNews.fldv_selected_date).format(
                                    "D MMM, yyyy"
                                  )}
                                </h4>
                              </div>
                            </div>
                          </Card.Body>

                          <div className="action">
                            <Button
                              variant="contained"
                              size="small"
                              className="readmore"
                              onClick={(e) =>
                                redirectArticle(
                                  e,
                                  arrNews.fldi_id,
                                  arrNews.fldv_title
                                )
                              }
                            >
                              Read More
                            </Button>

                            {/* {arrNews.is_bookmark ? (
                              <IconButton
                                style={{ color: "orange" }}
                                className="bookmark"
                                onClick={(e) =>
                                  handleRemoveBookMark(e, arrNews.fldi_id)
                                }
                              >
                                <Bookmark />
                              </IconButton>
                            ) : (
                              <IconButton
                                className="bookmark"
                                onClick={(e) =>
                                  handleAddBookMark(e, arrNews.fldi_id)
                                }
                              >
                                <BookmarkBorder />
                              </IconButton>
                            )} */}
                          </div>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Carousel>
              <IconButton
                onClick={handlePrev}
                className={singleData ? "singlePrevButton" : "prevButton"}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={handleNext}
                className={singleData ? "singleNextButton" : "nextButton"}
              >
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </section>
          {/* </Container>
        </section> */}
        </div>
      )}
    </React.Fragment>
  );
};

export default GrCircularHome;