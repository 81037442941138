import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as pwabuilder from "./pwabuilder-sw";
import { BrowserRouter } from "react-router-dom";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import Loading from "./components/Loading";
import "../node_modules/react-bootstrap/dist/react-bootstrap";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import { UserProvider } from "./utils/UserContext";
import { PWAProvider } from "./utils/PwaAppContext";
import Offline from './common/OfflinePage';

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOffline: !navigator.onLine,
    };
  }

  componentDidMount() {

    window.addEventListener("offline", this.handleOffline);
    window.addEventListener("online", this.handleOnline);
  }

  componentWillUnmount() {

    window.removeEventListener("offline", this.handleOffline);
    window.removeEventListener("online", this.handleOnline);
  }

  handleOffline = () => {
    this.setState({ isOffline: true });
  };

  handleOnline = () => {
    this.setState({ isOffline: false });
  };


  handleRetry = () => {
    if (navigator.onLine) {
      this.setState({ isOffline: false });
    } else {
      this.setState({ isOffline: true });
    }
  };

  render() {
    const isProduction = process.env.NODE_ENV === "production";
    const { version } = packageInfo;

    return (
      <React.StrictMode>
        <CacheBuster
          currentVersion={version}
          isEnabled={true}
          isVerboseMode={false}
          loadingComponent={<Loading />}
          metaFileDirectory={"."}
        >
          <BrowserRouter>
            <PWAProvider>
              <UserProvider>
                {this.state.isOffline ? <Offline onRetry={this.handleRetry} /> : <App />}
              </UserProvider>
            </PWAProvider>
          </BrowserRouter>
        </CacheBuster>
      </React.StrictMode>
    );
  }
}

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
