import React, { Component } from "react";
import ReactDOM1 from "react-dom";

import "./App.scss";
//import{Route, Switch, withRouter, HashRouter as Router } from "react-router-dom";
//import{Route, Switch, withRouter, BrowserRouter as Router } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Home from "./home/Home";
import About from "./about/About";
// import SecondExample from './home/SecondExample';
import Header from "./components/Header";
import Sign from "./login/Sign";
import Training from "./training/Training";
import TrainingSchedule from "./training/TrainingSchedule";
import PastTraining from "./training/PastTraining";
import PastTrainingSchedule from "./training/PastTrainingSchedule";
import Article from "./glimpses/Article";
import DiscussionBoard from "./discussion/DiscussionBoard";
import Category_tag_filter from "./discussion/Category_tag_filter";
import ReadThread from "./discussion/ReadThread";
import CreateThread from "./discussion/CreateThread";
import ArticleSingle from "./glimpses/ArticleSingle";
import News from "./govt-circular-gr/GovtCircular";
import NewsSingle from "./govt-circular-gr/NewsSingle";
// import Blog from "./blog/Blog";
import BlogInner from "./blog/BlogInner";
import Faq from "./faq/Faq";
import Contact from "./contact/Contact-us";
import Sample from "./discussion/Sample";
import { isLogin } from "./utils";
//import NetworkDetector from './utils/NetworkDetector';

import TermsCondition from "./terms-condition/TermsCondition";
import Webinar from "./webinar/Webinar";
import Upcoming_webinar from "./webinar/Upcoming_webinar";
import Past_webinar from "./webinar/Past_webinar";
import VideoGlimses from "./glimpses/VideoGlimses";
import PastWebinarSchedule from "./webinar/Past-Webinar-Schedule";

//import { initializeFirebase } from './push-notification';
import firebase from "./firebase";
import axios from "axios";
import { apiURL, getBuildDate } from "./utils";
import PrivacyPolicy from "./privacy-policy/Privacy-Policy";
//import ReactGA from 'react-ga';

import withClearCache from "./ClearCache";
import packageJson from "../package.json";
import PopUp from "./components/PopUp";
import ProfileUpdate from "./components/Profile/ProfileUpdate";
import ProfileInfo from "./components/Profile/ProfileInfo";
import Profile from "./components/Profile/Profile";
import BookMark from "./components/BookMark";
import ClearCache from "react-clear-cache";
import AcademicEssays from "./components/Article/AcademicEssays";
import SuccessStories from "./components/Article/SuccessStories";
import Interviews from "./components/Article/Interviews";
import BookRecommendations from "./components/Article/BookRecommendations";
import SelfDevelopment from "./components/Article/SelfDevelopment";
import TrForTeacher from "./components/TR-For-Teacher/TrForTeacher";
import SingleArticle from "./components/Article/SingleArticle";
import Blog from "./components/Article/Blog";
import SingleBlog from "./components/Article/SingleBlog";
import ArticleNews from "./components/Article/ArticleNews";
import MHBoard from "./govt-circular-gr/MHBoard";
import CBSEBoard from "./govt-circular-gr/CentralBoard";
import CentralBoard from "./govt-circular-gr/CentralBoard";
import Achievements from "./components/Achievements/Achievements";
import PwaNavMenu from "./components/PwaNavMenu";
import { PWAContext } from "./utils/PwaAppContext.jsx";
import { PwaNavigation } from "./components/PwaNavigation/PwaNavigation.jsx";

const ClearCacheComponent = withClearCache(MainApp);

function MainApp(props) {
  return (
    <div className="App">
      <p>Build date: {getBuildDate(packageJson.buildDate)}</p>
    </div>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isLogin()) {
        return <Component {...props} />;
      } else {
        // Store the last route in localStorage before redirecting
        localStorage.setItem('redirectAfterLogin', props.location.pathname);
        return <Redirect to="/sign" />;
      }
    }}
  />
);

const RedirectAfterSignIn = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      localStorage.setItem('redirectAfterLogin', props.location.pathname);
      return <Component {...props} />;
    }}
  />

);

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      navBarHeight: 0,
      screenWidth: 0,
    };
  }

  calculateNavBarHeight = () => {
    const screenHeight = window.screen.height;
    const viewportHeight = window.innerHeight;
    const navHeight = screenHeight - viewportHeight;
    const screenInnerWidth = window.screen.width;
    this.setState({ navBarHeight: navHeight, screenWidth: screenInnerWidth });
    console.log(this.state);
  };

  componentDidMount() {
    const searchParams = new URLSearchParams(window.location.search);
    const openPopupParam = searchParams.get("openpopup");
    if (openPopupParam === "true") {
      this.setState({ showPopup: true });
    }
    this.calculateNavBarHeight();
    window.addEventListener('resize', this.calculateNavBarHeight);

    document.querySelectorAll("a[target=_blank]").forEach((link) => {
      if (!link.hasAttribute("rel")) {
        link.setAttribute("rel", "noopener noreferrer");
      }

      // if (isPWA) {
      //   link.addEventListener("click", (event) => {
      //     event.preventDefault(); 
      //     window.open(link.href, "_system");
      //   });
      // }
    });

    const isPWAOpenLink =
      window.matchMedia("(display-mode: standalone)").matches ||
      window.navigator.standalone;

    const urlParams = new URLSearchParams(window.location.search);
    const isSharedLink = urlParams.has("source");

    if (!isPWAOpenLink && isSharedLink) {
      // If PWA is NOT installed, redirect to browser
      window.location.href = "https://navdisha.navneet.com/";
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateNavBarHeight);
  }

  handleClosePopup = () => {
    this.setState({ showPopup: false });
    // window.location.replace("https://www.navdisha.co.in/");
  };


  render() {
    return (
      <PWAContext.Consumer>
        {({ isPWA }) => (
          <React.Fragment>
            <PopUp
              open={this.state.showPopup}
              handleClose={this.handleClosePopup}
            />
            <Router basename="/">
              <div style={{ overflowX: isPWA ? "hidden" : "", overflow: "scroll" }}>
                {isPWA && <PwaNavMenu />}
                <Switch>
                  <RedirectAfterSignIn path="/about-us" component={About} />
                  <Route path="/header" component={Header} />
                  <Route path="/sign" component={Sign} />
                  <RedirectAfterSignIn
                    path="/training/:trainingId/:trainingTitle"
                    component={Training}
                  />
                  <RedirectAfterSignIn path="/overview" component={TrainingSchedule} />
                  <RedirectAfterSignIn path="/past-trainings" component={PastTraining} />
                  <RedirectAfterSignIn
                    path="/past-training-schedule/:trainingId/:trainingTitle"
                    component={PastTrainingSchedule}
                  />
                  <RedirectAfterSignIn path="/articles" component={Article} />
                  <RedirectAfterSignIn
                    path="/article/:articleId/:articleTitle"
                    component={SingleArticle}
                  />
                  <RedirectAfterSignIn path="/discussion-board" component={DiscussionBoard} />
                  <PrivateRoute
                    path="/discussion-board/:objectId"
                    component={Category_tag_filter}
                  />
                  <RedirectAfterSignIn path="/maharashtra-state-board" component={MHBoard} />
                  <RedirectAfterSignIn path="/central-board" component={CentralBoard} />
                  <RedirectAfterSignIn path="/news/:newsId/:newsTitle" component={NewsSingle} />
                  <RedirectAfterSignIn path="/blogs" component={Blog} />
                  <RedirectAfterSignIn path="/blog/:blogId/:blogTitle" component={SingleBlog} />
                  <RedirectAfterSignIn path="/faq" component={Faq} />
                  <RedirectAfterSignIn path="/contact-us" component={Contact} />
                  <RedirectAfterSignIn path="/academic-essays" component={AcademicEssays} />
                  <RedirectAfterSignIn path="/success-stories" component={SuccessStories} />
                  <RedirectAfterSignIn path="/interviews" component={Interviews} />
                  <RedirectAfterSignIn path="/news" component={ArticleNews} />
                  <RedirectAfterSignIn
                    path="/book-recommendations"
                    component={BookRecommendations}
                  />
                  <RedirectAfterSignIn path="/self-development" component={SelfDevelopment} />

                  <PrivateRoute
                    path="/thread-answer/:threadId/:threadTitle"
                    component={ReadThread}
                  />
                  <PrivateRoute path="/sample/:threadId/:threadTitle" component={Sample} />
                  <RedirectAfterSignIn path="/create-new-thread" component={CreateThread} />
                  <RedirectAfterSignIn path="/terms-and-conditions" component={TermsCondition} />
                  <RedirectAfterSignIn path="/privacy-policy" component={PrivacyPolicy} />
                  <RedirectAfterSignIn
                    path="/webinar/:webinarId/:webinarTitle"
                    component={Webinar}
                  />
                  <RedirectAfterSignIn path="/upcoming-webinars" component={Upcoming_webinar} />
                  <RedirectAfterSignIn path="/past-webinars" component={Past_webinar} />
                  <RedirectAfterSignIn
                    path="/past-webinar-schedule/:webinarId/:webinarTitle"
                    component={PastWebinarSchedule}
                  />
                  <Route path="/update-profile" component={ProfileUpdate} />
                  <Route path="/profile-info" component={Profile} />
                  <RedirectAfterSignIn path="/tr-for-teacher" component={TrForTeacher} />
                  <PrivateRoute path="/bookmark" component={BookMark} />
                  <Route path="/achievements" component={Achievements} />
                  <PrivateRoute path="/video" component={VideoGlimses} />
                  <RedirectAfterSignIn path="/pwa-navigation" component={PwaNavigation} />
                  <RedirectAfterSignIn path="/" component={Home} />
                  <Redirect from="*" to="/" />
                </Switch>
              </div>
            </Router>
          </React.Fragment>
        )}
      </PWAContext.Consumer>
    );
  }
}
export default App;