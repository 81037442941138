import React, { useEffect, useRef, useState } from 'react';
import '../style/PwaNavMenu.scss';
import axios from "axios";
import { apiURL } from '../utils';
import { setMenus as initialMenus } from "../common/navbarData";
import { useHistory } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { allowedMenuNames } from '../common/pwaNavbarMainMenu';
import articles from "../images/Icon_Articles.png"
import govtCirculars from "../images/Icon_Govt circular GR.png"
import trainingsIcon from "../images/Icon_Trainings.png"
import { MenuItem, MenuList, Popover } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import homeIcon from "../images/Icon_Home.png"
import { useLocation } from 'react-router-dom';


const PwaNavMenu = () => {
  const storedMenus = localStorage.getItem("menuData");
  const [openMenuId, setOpenMenuId] = useState(null);
  const [menus, setMenus] = useState(storedMenus ? JSON.parse(storedMenus) : initialMenus);
  const anchorRefs = useRef([]);
  const moreButtonRef = useRef(null);
  const history = useHistory();
  const [selectedNav, setSelectedNav] = useState(null);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);
  // const [navBarHeight, setNavBarHeight] = useState(0);
  // const [screenWidth, setScreenWidth] = useState(0);
  const location = useLocation();

  useEffect(() => {
    getMenus();
  }, []);


  // useEffect(() => {
  //   const calculateNavBarHeight = () => {
  //     const screenHeight = window.screen.height;
  //     const viewportHeight = window.innerHeight;
  //     const navHeight = screenHeight - viewportHeight;
  //     setNavBarHeight(navHeight);
  //     setScreenWidth(window.screen.width);
  //   };

  //   calculateNavBarHeight();

  //   window.addEventListener('resize', calculateNavBarHeight);

  //   return () => {
  //     window.removeEventListener('resize', calculateNavBarHeight);
  //   };
  // }, []);


  useEffect(() => {
    const currentPath = location.pathname;
    const findParentMenu = (menus) => {
      for (let menu of menus) {
        if (menu.fldv_to_path === currentPath) {
          return menu.fldi_id;
        }

        if (menu.arrChilds && menu.arrChilds.length > 0) {
          for (let child of menu.arrChilds) {
            if (child.fldv_to_path === currentPath) {
              return menu.fldi_id;
            }
          }
        }
      }
      return null;
    };
    const activeMenuId = findParentMenu(menus);
    if (activeMenuId === 6 || location?.state?.menudata === "trainings") {
      setSelectedNav(1);
    }
    if (activeMenuId === 5 || location?.state?.menudata === "articles") {
      setSelectedNav(2);
    }
    if (activeMenuId === 15 || location?.state?.menudata === "govt. circulars/gr") {
      setSelectedNav(3);
    }
    if (activeMenuId === 1) {
      setSelectedNav(0);
    }
    if (activeMenuId === 2 || activeMenuId === 23 || activeMenuId === 17 || activeMenuId === 18 || location?.state?.menudata === "more") {
      setSelectedNav(4);
    }
    if (location.pathname === "/bookmark" || location.pathname === "/achievements" || location.pathname === "/profile-info" || location.pathname === "/sign") {
      setSelectedNav(null);
    }
    if (location.pathname.startsWith("/webinar") || location.pathname.startsWith("/training") || location.pathname.startsWith("/past-webinar-schedule")) {
      setSelectedNav(1);
    }
    if (location.pathname.startsWith("/blog") || location.pathname.startsWith("/article")) {
      setSelectedNav(2);
    }
    if (location.pathname.startsWith("/news")) {
      setSelectedNav(3);
    }
  }, [location.pathname, menus, location.state]);


  const getMenuIcon = (menuName) => {
    switch (menuName.toLowerCase()) {
      case "home":
        return <img src={homeIcon} alt="trainings" className='pwa-nav-icon-image' />;
      case "trainings":
        return <img src={trainingsIcon} alt="trainings" className='pwa-nav-icon-image' />;
      case "articles":
        return <img src={articles} alt="articles" className='pwa-nav-icon-image' />;
      case "govt. circulars/gr":
        return <img src={govtCirculars} alt="govtCirculars" className='pwa-nav-icon-image' />;
      default:
        return <img src={trainingsIcon} alt="trainings" className='pwa-nav-icon-image' />;
    }
  };

  const getMenus = async () => {
    const headers = { "Content-type": "application/json" };
    try {
      const response = await axios.get(apiURL("menu/get_all_menu_test"), { headers });
      const resultData = response.data;
      if (resultData.status) {
        setMenus(resultData.result);
        localStorage.setItem("menuData", JSON.stringify(resultData.result));
      }
    } catch (error) {
      console.error("Error fetching menus:", error);
    }
  };

  // const handleToggle = (menuId, path) => {
  //   if (path) {
  //     handleClose(path); 
  //   } else if (openMenuId !== menuId) {
  //     setOpenMenuId(menuId); 
  //   } else {
  //     setOpenMenuId(null);
  //   }
  // };

  // const handleClose = (path) => {
  //   if (typeof path === "string") {
  //     setOpenMenuId(null); 
  //     history.push(path);
  //   }
  // };

  const handleMoreButtonClick = () => {
    history.push("/pwa-navigation", { menudata: "more" });
  };

  const handleSubMenuClick = (menu) => {
    if (menu.toLowerCase() === "trainings") {
      history.push("/pwa-navigation", { menudata: "trainings" });
    }
    if (menu.toLowerCase() === "articles") {
      history.push("/pwa-navigation", { menudata: "articles" });
    }
    if (menu.toLowerCase() === "govt. circulars/gr") {
      history.push("/pwa-navigation", { menudata: "govt. circulars/gr" });
    }
    if (menu.toLowerCase() === "home") {
      history.push("/");
    }
  }

  // const handleMoreMenuClose = () => {
  //   setMoreMenuOpen(false);
  // };

  return (
    <div className="pwa-nav-container">
      <BottomNavigation
        value={selectedNav}
        onChange={(event, newValue) => setSelectedNav(newValue)}
        showLabels
      >
        {menus
          .filter((menu) => allowedMenuNames.includes(menu.fldv_name))
          .map((menu) => (
            <BottomNavigationAction
              icon={getMenuIcon(menu.fldv_name)}
              key={menu.fldi_id}
              label={menu.fldv_name === "Govt. Circulars/GR"
                ? "Govt.Circulars"
                : menu.fldv_name}
              // ref={(el) => (anchorRefs.current[menu.fldi_id] = el)}
              onClick={() => handleSubMenuClick(menu.fldv_name)}
            />
          ))}
        <BottomNavigationAction
          icon={<MenuIcon className='pwa-nav-more-icon-image' />}
          ref={moreButtonRef}
          onClick={handleMoreButtonClick}
          label="More"
        />
      </BottomNavigation>

      {/* {menus.map((menu) => (
        menu.arrChilds?.length > 0 && (
          <PopoverMenu
            key={menu.fldi_id}
            anchorRef={{ current: anchorRefs.current[menu.fldi_id] }} // Pass the correct ref
            open={openMenuId === menu.fldi_id}
            handleClose={handleClose}
            items={menu.arrChilds}
          />
        )
      ))} */}

      {/* <Popover
        open={moreMenuOpen}
        anchorEl={moreButtonRef.current}
        onClose={handleMoreMenuClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuList>
          {menus 
        .filter((menu) => !allowedMenuNames.includes(menu.fldv_name))
            .map((menu) => (
              <MenuItem
                key={menu.fldi_id}
                onClick={() => {
                  handleClose(menu.fldv_to_path);
                  handleMoreMenuClose();
                }}
              >
             <div className='pwa-nav-menu-child-label'>
              <span>{getMenuIcon(menu.fldv_name)} </span>
              <span> {menu.fldv_name}</span>
              </div>
              </MenuItem>
            ))}
        </MenuList>
      </Popover> */}
    </div>
  );
};

export default PwaNavMenu;
