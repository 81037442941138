import React from 'react';

const WorkshopTable = () => {
    const data = [
        { region: 'Mumbai', workshops: 17, participants: 684 },
        { region: 'Nashik', workshops: 20, participants: 1034 },
        { region: 'Pune', workshops: 12, participants: 742 },
        { region: 'Nagpur', workshops: 18, participants: 1048 },
        { region: 'Aurangabad', workshops: 16, participants: 1040 },
        { region: 'Satara', workshops: 5, participants: 401 },
    ];

    const totalWorkshops = data.reduce((acc, curr) => acc + curr.workshops, 0);
    const totalParticipants = data.reduce((acc, curr) => acc + curr.participants, 0);

    return (
        <div style={{ padding: '20px' }}>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr style={{ backgroundColor: '#A4C8E1' }}>
                        <th style={{ padding: '10px', textAlign: 'left' }}>Region</th>
                        <th style={{ padding: '10px', textAlign: 'center' }}>Number of Workshops held in 2024-25</th>
                        <th style={{ padding: '10px', textAlign: 'center' }}>Number of Participants</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} style={{ backgroundColor: index % 2 === 0 ? '#E3F2FD' : '#ffffff' }}>
                            <td style={{ padding: '10px', textAlign: 'left', fontWeight: 600 }}>{item.region}</td>
                            <td style={{ padding: '10px', textAlign: 'center', fontWeight: 600 }}>{item.workshops}</td>
                            <td style={{ padding: '10px', textAlign: 'center', fontWeight: 600 }}>{item.participants}</td>
                        </tr>
                    ))}
                    <tr style={{ backgroundColor: '#A4C8E1' }}>
                        <td style={{ padding: '10px', textAlign: 'left', fontWeight: 600 }}><strong>Total</strong></td>
                        <td style={{ padding: '10px', textAlign: 'center', fontWeight: 600 }}><strong>{totalWorkshops}</strong></td>
                        <td style={{ padding: '10px', textAlign: 'center', fontWeight: 600 }}><strong>{totalParticipants}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default WorkshopTable;
