import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Typography,
  FormHelperText,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  ListItemIcon,
  Grid,
  Snackbar,
} from "@material-ui/core";
import "../../style/RegForm.scss";
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, Field } from "formik";
import { getID, apiURL } from "../../utils";
import Moment from "moment";
import axios from "axios";
import * as yup from "yup";
import EmailVerified from "../../login/EmailVerified";
import { Fragment } from "react";
import { CloseOutlined } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
const regSchema = yup.object().shape({
  firstname: yup.string().required("This field is required."),
  lastname: yup.string().required("This field is required."),
  role: yup.string().required("This field is required."),
  city: yup.string().required("This field is required."),
  //   schcollname: yup.string().required("This field is required."),
  //   pincode: yup.string().required("This field is required."),
  //   board: yup.string().required("This field is required."),
  //   grades: yup.string().required("This field is required."),
  // subjects: yup.string().required("This field is required."),
  //   email: yup.string().required("This field is required."),
});
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#00aad3",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const boardAndMedium = [
  "SSC-Marathi",
  "SSC-English",
  "SSC-Hindi",
  "SSC-Urdu",
  "CBSE",
  "ICSE",
  "Others",
];
const options = [
  "Do not Teach",
  "Pre-Primary (Nursery- Senior Kg)",
  "Primary (Grade 1 to Grade 4)",
  "Middle School (Grade 5 to Grade 7)",
  "High School (Grade 8 to Grade 10)",
  "Higher Secondary (Grade 11 to Grade 12)",
  "Others",
];
const subjectList = [
  "Do not Teach",
  "All Subjects",
  "English",
  "Marathi",
  "Hindi",
  "Urdu",
  "Mathematics",
  "Science",
  "Social Sciences",
  "Commerce Faculty",
  "Arts Faculty",
  "Science Faculty",
  "Others",
];
const ProfileForm = ({
  userData,

  onClose,
}) => {
  const [cityList, setCityList] = useState([]);
  const [authEmail, setAuthEmail] = useState("");
  const [otpBoxOpen, setOtpBoxOpen] = useState(false);
  const [isEmailAdresssVerified, setEmailAddressVerified] = useState(false);
  const [sendOtpString, setSendOtpString] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState("");
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isEmailVerifiedMsg, setIsEmailVerifiedMsg] = useState("");
  const [grades, setSelected] = useState(userData.grades || []);
  const [subjects, setSubject] = useState(userData.subjects || []);
  const [board, setBoard] = useState(userData.board || "");
  const emailVerified = localStorage.getItem("isEmailVerified");
  const [open, setOpen] = React.useState(false);
  const [openSubjectDrop, setSubjectDrop] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMsg, setSnackBarMsg] = useState("");

  useEffect(() => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .get(apiURL("common/get_city/" + 12), { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setCityList(resultData.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const check_email = (e) => {
    if (e.target.value) {
      if (
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          e.target.value
        )
      ) {
        const headers = {
          "content-type": "application/json",
          Accept: "application/json",
        };
        const body = { email: e.target.value };
        axios
          .post(apiURL("register/check_email"), body, { headers: headers })
          .then((response) => {
            const resultData = response.data;

            if (resultData.status === true || resultData.status === 1) {
              setOtpBoxOpen(false);
              setEmailError(true);
              setEmailErrorMsg(resultData.message);
            } else {
              setEmailError(false);
              setEmailErrorMsg("");
            }
          });
      } else {
        setOtpBoxOpen(false);
        setEmailError(true);
        setEmailErrorMsg("Please Enter Valid Email Address");
      }
    }
  };
  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const re = /^\d+$/;
    if (!re.test(keyValue)) {
      e.preventDefault();
    }
  };
  const handleTextKeyPress = (event) => {
    const { name, value } = event.target;

    const regex = /^[a-zA-Z\s]*$/;

    if (!regex.test(event.key)) {
      event.preventDefault();
    }
  };

  const sendOTP = (e, isCancelButtonClicked) => {
    if (isCancelButtonClicked) {
      // If cancel button is clicked, do nothing
      return;
    }
    const inputValue = e.target.value.trim();

    if (
      !isEmailAdresssVerified &&
      new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(inputValue) &&
      !emailError &&
      authEmail.length === 0
    ) {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        email: inputValue,
        //"number": this.state.mobileNumber,
      };
      axios
        .post(apiURL("register/send_otp_email"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            setAuthEmail(atob(decodeURIComponent(resultData.result)));
            setOtpBoxOpen(true);
            setSendOtpString(false);
            setEmail(document.getElementById("email").value);

            localStorage.setItem("otp", resultData.result);
          }
        });
    } else if (email.length > 0) {
      setOtpBoxOpen(false);
      setSendOtpString(false);
      setEmail(document.getElementById("email").value);
    } else {
      setSendOtpString(false);
    }
  };

  const otpBoxClose = (event) => {
    if (event === true) {
      setAuthEmail("");
      setOtpBoxOpen(false);
      setEmailAddressVerified(true);
      setSendOtpString(false);
      setIsEmailVerified(false);
      setIsEmailVerifiedMsg("");
    }
  };

  const onClickOTPClose = (event) => {
    if (event === true) {
      setOtpBoxOpen(false);
      setEmailAddressVerified(false);
      setIsEmailVerified(true);
      setIsEmailVerifiedMsg("Please verify email address");
    }
  };
  const changeNumber = (event) => {
    if (event === true) {
      setAuthEmail("");
      setOtpBoxOpen(false);
      setEmailAddressVerified(false);
      setIsEmailVerified(false);
      setIsEmailVerifiedMsg("");
    }
  };

  const handleSubmit = (values) => {
    const filteredSubjects = subjects.filter((subject) =>
      subjectList.includes(subject)
    );
    const isAnyFieldEmpty = Object.values({
      city: values.city,
      contact: values.contact,
      firstname: values.firstname,
      lastname: values.lastname,
      pincde: values.pincode,
      role: values.role,
      schoolname: values.schcollname,
      grades: grades,
      subjects: filteredSubjects,
      board: board,
    }).some((value) => value === "");
    if (isAnyFieldEmpty) {
      alert("Please fill all the details");
      return;
    }
    // if (emailError || isEmailVerified) {
    //   return;
    // } else if (
    //   values.email !== "" &&
    //   emailVerified === null &&
    //   !isEmailAdresssVerified
    // ) {
    //   return;
    // } else {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      fldi_id: getID(),
      first_name: values.firstname,
      last_name: values.lastname,
      status: values.role,
      email: values.email,
      city: values.city,
      sch_col: values.schcollname,
      pincode: values.pincode,
      board_medium: board,
      grades_taught: grades,
      subjects_taught: subjects,
    };
    axios
      .post(apiURL("teacher/edit_profile"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;

        if (resultData.status === true || resultData.status === 1) {
          localStorage.setItem(
            "user_detail",
            JSON.stringify(resultData.result)
          );
          const updatedDetails = {
            firstname: body.first_name,
            lastname: body.last_name,
            contact: values.contact,
            email: body.email,

            role: body.status,
            city: body.city,
            schcollname: body.sch_col,
            pincode: body.pincode,
            board: body.board_medium,
            grades: body.grades_taught,
            subjects: body.subjects_taught,
          };
          onClose(updatedDetails);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // }
  };
  const handleClose = () => {
    const filteredGrades = grades.filter((grade) => grade !== undefined);
    setSelected(filteredGrades);
    setOpen(false);
  };

  const handleOpen = () => setOpen(true);
  const handleSubjectDropOpen = () => {
    setSubjectDrop(true);
  };

  const handleSubjectDropClose = () => {
    const filteredSubject = subjects.filter((sub) => sub !== undefined);
    setSubject(filteredSubject);
    setSubjectDrop(false);
  };
  const handleBoardChange = (e) => {
    setBoard(e.target.value);
  };
  const handleDropChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      setSelected(options.filter((option) => option !== "all"));
    } else {
      const filteredValue = value.filter((val) => val !== undefined);
      setSelected(filteredValue);
    }
  };
  const handleSubjectDropChange = (event) => {
    const value = event.target.value;
    if (value.includes("all")) {
      setSubject(options.filter((option) => option !== "all"));
    } else {
      const filteredValue = value.filter((val) => val !== undefined);
      setSubject(filteredValue);
    }
  };
  const classes = useStyles();

  return (
    <Fragment>
      <Formik
        initialValues={userData}
        validationSchema={regSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, errors, touched }) => (
          <Form className="regForm">
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Typography className="mt-4">
                  First Name:<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  className="mt-2"
                  name="firstname"
                  value={values.firstname}
                  onChange={handleChange}
                  onKeyPress={handleTextKeyPress}
                  variant="standard"
                  size="small"
                  error={errors.firstname && touched.firstname}
                  helperText={
                    errors.firstname && touched.firstname
                      ? errors.firstname
                      : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className="mt-4">
                  Last Name:<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  name="lastname"
                  className="mt-2"
                  value={values.lastname}
                  onChange={handleChange}
                  onKeyPress={handleTextKeyPress}
                  fullWidth
                  margin="normal"
                  error={errors.lastname && touched.lastname}
                  helperText={
                    errors.lastname && touched.lastname ? errors.lastname : null
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="contact"
                  className="mt-4"
                  label="Mobile Number"
                  value={values.contact}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className="mt-4">
                  City:<span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  className="mt-2"
                  labelId="multi-select-label"
                  name="city"
                  fullWidth
                  value={values.city}
                  onChange={handleChange}
                >
                  {cityList.map((arrCity, index) => {
                    return (
                      <MenuItem key={arrCity.id} value={arrCity.city}>
                        {arrCity.city}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className="mt-4">
                  Role:<span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  name="role"
                  className="mt-2"
                  value={values.role}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="Principal">Principal</MenuItem>
                  <MenuItem value="Teacher">Teacher</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className="mt-4">
                  School Name/College Name:
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  name="schcollname"
                  className="mt-2"
                  value={values.schcollname}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                //   error={errors.schcollname && touched.schcollname}
                //   helperText={
                //     errors.schcollname && touched.schcollname
                //       ? errors.schcollname
                //       : null
                //   }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className="mt-4">
                  Pincode:<span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  name="pincode"
                  className="mt-2"
                  value={values.pincode}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  onKeyPress={handleKeyPress}
                  inputProps={{ maxLength: 6, inputMode: "numeric" }}
                //   error={errors.pincode && touched.pincode}
                //   helperText={
                //     errors.pincode && touched.pincode ? errors.pincode : null
                //   }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className="mt-4">
                  Board & Medium:<span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  className="mt-2"
                  id="board"
                  name="board"
                  fullWidth
                  value={board}
                  onChange={handleBoardChange}
                  renderValue={(selected) => {
                    if (boardAndMedium.includes(selected)) {
                      return selected;
                    } else {
                      return "";
                    }
                  }}
                //   error={errors.board && touched.board}
                //   helperText={
                //     errors.board && touched.board ? errors.board : null
                //   }
                >
                  {boardAndMedium.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className="mt-4">
                  Grades Taught/Managed:<span style={{ color: "red" }}>*</span>
                </Typography>
                <Select
                  className="mt-2"
                  name="grades"
                  labelId="mutiple-select-label"
                  multiple
                  value={grades}
                  onChange={handleDropChange}
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  renderValue={(grades) => {
                    if (grades.length === 0) {
                      return "None";
                    }
                    return grades.join(", ");
                  }}
                  MenuProps={MenuProps}
                  fullWidth
                //   error={errors.grades && touched.grades}
                //   helperText={
                //     errors.grades && touched.grades ? errors.grades : null
                //   }
                >
                  <MenuItem
                    onClick={handleClose}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Button
                      style={{ marginTop: "0.1rem" }}
                      color="info"
                      size="small"
                    >
                      <CloseOutlined />
                    </Button>
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          style={{ color: "#00aad3" }}
                          checked={grades.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className="mt-4">
                  Subject Taught:<span style={{ color: "red" }}>*</span>
                </Typography>

                <Select
                  className="mt-2"
                  labelId="multi-select-label"
                  id="subjects"
                  name="subjects"
                  fullWidth
                  multiple
                  open={openSubjectDrop}
                  value={subjects}
                  onChange={handleSubjectDropChange}
                  onOpen={handleSubjectDropOpen}
                  onClose={handleSubjectDropClose}
                  renderValue={(subjects) => {
                    if (subjects.length === 0) {
                      return "";
                    }
                    const validSubjects = subjects.filter((subject) =>
                      subjectList.includes(subject)
                    );
                    return validSubjects.join(", ");
                  }}
                  MenuProps={MenuProps}
                //   error={errors.subjects && touched.subjects}
                //   helperText={
                //     errors.subjects && touched.subjects ? errors.subjects : null
                //   }
                >
                  <MenuItem
                    onClick={handleSubjectDropClose}
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Button
                      style={{ marginTop: "0.1rem" }}
                      color="info"
                      size="small"
                    >
                      <CloseOutlined />
                    </Button>
                  </MenuItem>
                  {subjectList.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          style={{ color: "#00aad3" }}
                          checked={subjects.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Typography className="mt-4">
                  Email Address:
                </Typography>
                <TextField
                  className="mt-2"
                  disabled={
                    emailVerified === null || values.email === "" ? false : true
                  }
                  type="text"
                  fullWidth
                  variant="standard"
                  name="email"
                  id="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={(e) => {
                    // check_email(e);
                    // sendOTP(e);
                    // emailValid(e);
                  }}
                  // InputProps={{
                  //   endAdornment: (
                  //     <Button
                  //       disabled={authEmail.length === 0}
                  //       style={{
                  //         justifyContent: "center",
                  //         borderWidth: 1,
                  //         fontSize: 10,
                  //       }}
                  //       onClick={() => {
                  //         setOtpBoxOpen(true);
                  //         setSendOtpString(false);
                  //       }}
                  //     >
                  //       Enter Otp
                  //     </Button>
                  //   ),
                  // }}
                  // onKeyUp={(e) => check_email(e)}
                  error={
                    (errors.email && touched.email) ||
                    emailError ||
                    isEmailVerified
                  }
                  helperText={
                    errors.number && touched.number
                      ? errors.number
                      : null || emailErrorMsg || isEmailVerifiedMsg
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  //   onClick={(e) => this.registerNow(e)}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className="mt-4 text-capitalize"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <EmailVerified
        otpBoxOpen={otpBoxOpen}
        otpValue={authEmail}
        onClickOTPClose={(e) => onClickOTPClose(e)}
        otpBoxClose={(e) => otpBoxClose(e)}
        email={email}
        changeNumber={(e) => changeNumber(e)}
      />
    </Fragment>
  );
};

export default ProfileForm;
