import React, { useState, useEffect, useRef } from "react";
import { List } from "bootstrap-4-react";
import Navmenu from "../components/Navmenu";
import Appbar from "../components/Appbar";
import "../style/Webinar.scss";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import {
  ArrowBack,
  LocationOn,
  Bookmark,
  BookmarkBorder,
  CloseOutlined,
} from "@material-ui/icons";
import {
  apiURL,
  isLogin,
  getName,
  getEmail,
  getContact,
  getID,
  getLastName,
  getRole,
  getBoard,
  getGrades,
  getSubjects,
  getCity,
  getPincode,
  getSchCollName,
} from "../utils";
import axios from "axios";
import Moment from "moment";
import SanitizedHTML from "react-sanitized-html";
import Sign from "../login/Sign";
import { IoMdCheckmarkCircleOutline } from "@react-icons/all-files/io/IoMdCheckmarkCircleOutline";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Snackbar,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import ShareDialog from "../components/ShareDialog";
import dateImage from "../images/date.png";
import timeImage from "../images/time.png";
import durationImage from "../images/duration.png";
import priceImage from "../images/price.png";
import Video_dialog from "../Video_dialog";
import Meta from "../meta/Meta";
import RegistrationForm from "./RegistrationForm";
import ShareNews from "../govt-circular-gr/ShareNews";
import TrainingDefaultImageLoader from "../training/TrainingDefaultImage";
const styles = (theme) => ({
  root: {
    maxWidth: "100%",
  },
  speakerImage: {
    width: "50%",
    height: "auto",
    borderRadius: "50%",

    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
  },
  speakerBody: {
    position: "absolute",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
    },
    [theme.breakpoints.between("md", "lg")]: {
      /* Apply styles for large mobile screens */ position: "absolute",
    },
    [theme.breakpoints.between(750, 950)]: {
      /* Your styles for devices between 800px and 950px */
      /* For example: */
      position: "absolute",
    },
    // [theme.breakpoints.down("lg")]: {
    //   position: "absolute",
    // },
  },
});
const orgGrades = getGrades()
  ? getGrades()
      .split(",")
      .map((grade) => grade.trim())
  : [];
const orgSubjects = getSubjects()
  ? getSubjects()
      .split(",")
      .map((sub) => sub.trim())
  : [];
const initialValues = {
  firstname: getName(),
  lastname: getLastName(),
  role: getRole(),
  contact: getContact(),
  email: getEmail(),
  city: getCity(),
  schcollname: getSchCollName(),
  pincode: getPincode(),
  board: getBoard(),
  grades: orgGrades,
  subjects: orgSubjects,
};
function Webinar(props) {
  const { classes, history, match } = props;
  const [eventData, setEventData] = useState([]);
  const [noData, setNoData] = useState("");
  const [points, setPoints] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [joinNow, setJoinNow] = useState(false);
  const [showComponent, setShowComponent] = useState(false);
  const [title, setTitle] = useState("");
  const [name, setName] = useState(getName());
  const [email, setEmail] = useState(getEmail());
  const [contact, setContact] = useState(getContact());
  const [dialogOpen, setDialogOpen] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [is_bookmark, setIs_bookmark] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [registerDialog, setRegisterDialog] = useState(false);
  const [id, setId] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severityColor, setSeverityColor] = useState("");
  const [snackBarTimer, setSnackBarTimer] = useState("");

  const _sign = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getWebinar();
    checkUserRegister();
    setIsMobile(window.innerWidth <= 1000);
  }, [match.params.webinarId]);

  const handleRegistrationSuccess = (isJoined, webinarId) => {
    if (isJoined) {
      setJoinNow(true);
    }
  };

  const checkUserRegister = () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      webinar_id: match.params.webinarId,
      user_id: getID(),
    };
    axios
      .post(apiURL("join_webinar_user/check_join_user"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setJoinNow(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getWebinar = () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      webinar_id: match.params.webinarId,
      user_id: getID(),
    };
    axios
      .post(apiURL("webinar/get_webinar_by_id"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setEventData(resultData?.result);
          setIs_bookmark(resultData?.result?.is_bookmark);
          const speakers = resultData?.result?.speaker;
          setSpeakers(speakers);
          const points = resultData?.result?.fldt_points;
          setPoints(points);
        } else {
          setNoData("Coming Soon");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const registerNow = () => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      webinar_id: match.params.webinarId,
      user_id: getID(),
    };
    axios
      .post(apiURL("join_webinar_user/add"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setJoinNow(true);
          getWebinar();
          setRegisterDialog(false);
          setName("");
          setEmail("");
          setContact("");
          setShowSnackbar(true);
          setSnackBarMessage(
            "Thank you for getting in touch! We appreciate you contacting us...."
          );
          setSeverityColor("success");
          setSnackBarTimer("3000");
        } else {
          setNoData("Coming Soon");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleShareClick = () => {
    setShowShareDialog(true);
  };

  const handleCloseShareDialog = () => {
    setShowShareDialog(false);
  };

  const checkJoinNow = () => {
    checkUserRegister();
    getWebinar();
    setShowComponent(false);
    setRegisterDialog(true);
    setName(getName());
    setEmail(getEmail());
    setContact(getContact());
  };

  const handleClickOpen = (e, webinarId, title, date, time) => {
    if (!isLogin()) {
      setShowComponent(true);
    } else {
      setRegisterDialog(true);
      setId(webinarId);
      setTitle(title);
      setDate(date);
      setTime(time);
    }
  };

  const handleBack = (event) => {
    history.push("/upcoming-webinars");
  };

  const registerWebinarClose = () => {
    setRegisterDialog(false);
    setId("");
    setTitle("");
  };

  const videoDialog = (e, videoUrl) => {
    if (!isLogin()) {
      setShowComponent(true);
    } else {
      setDialogOpen(true);
      setVideoUrl(videoUrl);
    }
  };

  const videoDialogClose = () => {
    setDialogOpen(false);
    setVideoUrl("");
  };

  const snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const handleAddBookMark = (id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.success === true || resultData.success === 1) {
          setIs_bookmark(true);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleRemoveBookMark = (id) => {
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setIs_bookmark(false);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const now = Moment(); // Get current time
  const eventDate = Moment(eventData.fldv_select_date, "YYYY-MM-DD"); // Parse date correctly
  const cutoffTime = Moment().set({ hour: 19, minute: 0, second: 0, millisecond: 0 }); // 7:00 PM today
  
  let isDisabled = false;
  
  if (eventDate.isSame(now, 'day')) {
    // If the date is today
    if (now.isBefore(cutoffTime)) {
      isDisabled = false; // Before 7 PM, show false
    } else {
      isDisabled = true; // After 7 PM, show true
    }
  } else if (eventDate.isBefore(now, 'day')) {
    // If the date is in the past
    isDisabled = true;
  }
  
  return (
    <React.Fragment>
      <Meta
        tableName={"webinar"}
        objectId={match.params.webinarId}
        forPage={"inner_page"}
      />
      {showComponent ? (
        <Sign
          ref={_sign}
          pageProp={props}
          checkJoinNow={(e) => checkJoinNow(e)}
        />
      ) : (
        <div>
          <Appbar />
          <div className="sticky-top sign-light">
            <Navmenu />
          </div>
          <section className="upcomingWebMain"  >
            <Container>
              <h1>{eventData.fldv_title}</h1>
              <List inline>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
                <List.Item inline></List.Item>
              </List>
              <Row className="d-flex">
                <Col
                  lg="6"
                  md="12"
                  sm="12"
                  className="order-lg-1 order-md-1 order-1"
                >
                  <h5 className="speakerName">{eventData.fldv_speaker_name}</h5>
                  {/* <h5>{this.state.points.map((point) => point.point)}</h5> */}
                  <div className="fixed-content">
                    <SanitizedHTML
                      allowedAttributes={false}
                      allowedTags={false}
                      html={eventData.fldv_description}
                    />
                  </div>
                </Col>
                <Col
                  lg="6"
                  md="12"
                  sm="12"
                  className="webVideo order-lg-2 order-md-2 order-2"
                >
                 
                    <div className="player-wrapper">
                    <TrainingDefaultImageLoader
                      imageUrl={eventData.fldv_event_image}
                      className="react-player"
                      />
                      {/* <img
                        src={eventData.fldv_event_image}
                        className="react-player"
                        controls="true"
                        width="100%"
                        height="100%"
                      /> */}
                    </div>
                   {/* (
                    <div className="player-wrapper"></div>
                  )} */}

                  {/* <Video /> */}
                </Col>{" "}
              </Row>
              <Row>
                <Col
                  lg="6"
                  md="12"
                  sm="12"
                  className="order-lg-1 order-md-1 order-1"
                >
                  <table className="table">
                    {/* <thead>
                                    <tr>
                                      <th>Key</th>
                                      <th>Value</th>
                                    </tr>
                                  </thead> */}
                    <tbody>
                      <tr>
                        <td>
                          <img
                            src={dateImage}
                            className="img-fluid"
                            // width="30px"
                          />{" "}
                          :{" "}
                          {`${Moment(eventData.fldv_select_date).format(
                            "ddd, D MMM, YYYY"
                          )}, ${Moment(eventData.fldt_time).format("LT")}`}
                        </td>
                        <td>
                          {" "}
                          <img
                            src={durationImage}
                            className="img-fluid"
                            // width="30px"
                          />{" "}
                          : {eventData.fldv_duration} Hours
                        </td>
                        {/* <td>
                            <img
                              src={timeImage}
                              className="img-fluid"
                              width="30px"
                            />{" "}
                            :{" "}
                            {Moment(this.state.eventData.fldt_time).format(
                              "hh:mmA"
                            )}
                          </td> */}
                      </tr>
                      <tr>
                        <td>
                          <LocationOn
                            color="primary"
                            // style={{ fontSize: "30" }}
                          />
                          : {eventData.fldv_venue_location}
                        </td>
                        <td>
                          {" "}
                          <img
                            src={priceImage}
                            className="img-fluid"
                            // width="30px"
                          />{" "}
                          : {eventData.fldv_price}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col
                  lg="6"
                  md="12"
                  sm="12"
                  className="webVideo order-lg-2 order-md-2 order-2"
                >
                  <div className="register mt-2 d-flex justify-content-center">
                    {joinNow ? (
                      <Button
                        style={{ backgroundColor: "#064e89", color: "#fff" }}
                      >
                        Registered
                        <span>
                          <IoMdCheckmarkCircleOutline />
                        </span>
                      </Button>
                    ) : (
                      <Button
                        style={{
                          backgroundColor: isDisabled ? "#2f5d82" : "#064e89",
                          color: "#e0e0e0",
                          cursor: isDisabled ? "not-allowed" : "pointer",
                          opacity: isDisabled ? 0.6 : 1,
                        }}

                        disabled={isDisabled}
                        onClick={(e) =>
                          handleClickOpen(
                            e,
                            eventData.fldi_id,
                            eventData.fldv_title,
                            Moment(eventData.fldv_select_date).format(
                              "MM DD YYYY"
                            ),
                            Moment(eventData.fldt_time).format("LT")
                          )
                        }
                      >
                        Register
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="buttonContainer">
            <Container>
              <div className="action">
                {is_bookmark === true ? (
                  <IconButton
                    variant="contained"
                    className="bookmark"
                    style={{
                      color: "orange",
                      border: "3px solid #064e89",
                    }}
                    onClick={() => handleRemoveBookMark(eventData.fldi_id)}
                  >
                    <Bookmark />
                  </IconButton>
                ) : (
                  <IconButton
                    variant="contained"
                    className="bookmark"
                    style={{
                      color: "#064e89",
                      border: "3px solid #064e89",
                    }}
                    onClick={() => handleAddBookMark(eventData.fldi_id)}
                  >
                    <BookmarkBorder />
                  </IconButton>
                )}
              </div>
              <div className="shareSection">
                <ShareNews
                  trainingId={eventData.fldi_id}
                  shareUrl={window.location.href}
                  title={eventData.fldv_title}
                  date={Moment(eventData.fldv_select_date).format(
                    "dddd, D MMM, YYYY"
                  )}
                  time={Moment(eventData.fldv_from_time).format("hh:mmA")}
                />
              </div>
              <Row>
                <Button
                  style={{ background: "#064e89", color: "white" }}
                  variant="contained"
                  onClick={handleBack}
                  className="back"
                >
                  Back
                </Button>
              </Row>
            </Container>
          </section>

          <Footer />
          <ShareDialog
            open={showShareDialog}
            onClose={handleCloseShareDialog}
            webinarId={eventData.fldi_id}
            shareUrl={eventData.fldv_webinar_url}
            title={eventData.fldv_title}
            date={Moment(eventData.fldv_select_date).format(
              "dddd, D MMM, YYYY"
            )}
            time={Moment(eventData.fldv_select_date).format("hh:mmA")}
          />
          <Dialog
            onClose={(e) => registerWebinarClose(e)}
            aria-labelledby="customized-dialog-title"
            open={registerDialog}
          >
            <div className="register-webinar-modal-header-mob">
              <DialogTitle
                id="customized-dialog-title"
                className="register-webinar-modal-header-mob-txt"
                onClose={(e) => registerWebinarClose(e)}
              >
                {eventData.fldv_title}
              </DialogTitle>
              <CloseOutlined className="register-webinar-modal-close-btn" onClick={(e) => registerWebinarClose(e)} />
            </div>
            <DialogContent>
              {/*<form id="login" onSubmit={(e) => this.registerNow(e)}>*/}
              <RegistrationForm
                id={id}
                userData={initialValues}
                date={date}
                time={time}
                onClose={registerWebinarClose}
                onSuccess={(isJoined) =>
                  handleRegistrationSuccess(isJoined, id)
                }
              />
              {/*</form>*/}
            </DialogContent>
            <DialogActions></DialogActions>
          </Dialog>

          <Snackbar
            open={showSnackbar === true}
            autoHideDuration={snackBarTimer}
            onClose={snackBarClose}
          >
            <Alert
              onClose={snackBarClose}
              severity={severityColor}
              elevation={6}
              variant="filled"
            >
              {snackBarMessage}
            </Alert>
          </Snackbar>

          <Video_dialog
            dialogOpen={dialogOpen}
            videoUrl={videoUrl}
            onClose={(e) => videoDialogClose(e)}
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default withStyles(styles)(Webinar);
