import React, { useState, useEffect, useRef } from "react";
import "../style/OtherWeb.scss";
import { Row, Col, Card } from "bootstrap-4-react";
import Footer from "../components/Footer";
import Button from "@material-ui/core/Button";
import { Container, Grid } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { apiURL, getID, isLogin } from "../utils";
import axios from "axios";
import Moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import { Snackbar, IconButton } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import WebinarForm from "../webinar/WebinarForm";
import Video_dialog from "../Video_dialog";
import Meta from "../meta/Meta";
import { ArrowLeft, BookmarkBorder } from "@material-ui/icons";
import { Bookmark } from "@material-ui/icons";
import logoNew from "../images/navdisha.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArticleImageLoader from "../components/Article/ArticleImageLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
  },
  dialogPaper: {
    // Add any styles for dialogPaper if needed
  },
}));

const OtherWebinar = ({ id }) => {
  const classes = useStyles();
  const history = useHistory();
  const carouselRef = useRef();

  const [state, setState] = useState({
    pastEventsList: [],
    id: "",
    title: "",
    page: 1,
    per: 4,
    selectedItem: 0,
    isMobile: false,
    open: false,
    showComponent: false,
    loading: false,
    noData: "",
    showSnackbar: false,
    snackBarTimer: 3000,
    severityColor: "success",
    snackBarMessage: "",
    dialogOpen: false,
    videoUrl: "",
  });

  useEffect(() => {
    getPastEvents();
    setState((prevState) => ({
      ...prevState,
      isMobile: window.innerWidth <= 1000,
    }));

    return () => {
      // Cleanup if needed
    };
  }, []);

  const handleClickOpen = (e, id, title) => {
    if (!isLogin()) {
      setState((prevState) => ({ ...prevState, showComponent: true }));
    } else {
      setState((prevState) => ({ ...prevState, open: true, id: id, title: title }));
    }
  };

  const handleReadMore = (e, id, title) => {
    e.preventDefault();
    addView(id)
    if (!isLogin()) {
      setState((prevState) => ({ ...prevState, showComponent: true }));
    } else {
      history.push("/past-webinar-schedule/" + id + "/" + title);
    }
  };
  const addView = (id) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      webinar_id:id,
      user_id: getID(),
    };
    axios
      .post(apiURL("views/add_views"), body, { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          console.log(resultData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPastEvents = () => {
    setState((prevState) => ({ ...prevState, loading: true }));
    const { per, page } = state;
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      status: 1,
      event_type: "PE",
      user_id: getID(),
      event_mode: "Online"
    };
    axios
      .post(apiURL("webinar/events"), body, {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setState((prevState) => ({
            ...prevState,
            pastEventsList: resultData.result,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            noData: "",
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAddBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isLogin()) {
      setState((prevState) => ({ ...prevState, showComponent: true }));
    } else {
      const headers = {
        "content-type": "application/json",
        Accept: "application/json",
      };
      const body = {
        content_id: id,
        content_type: "past_webinar",
        user_id: getID(),
      };
      axios
        .post(apiURL("bookmark/addBookmark"), body, { headers: headers })
        .then((response) => {
          const resultData = response.data;
          if (resultData.success === true || resultData.success === 1) {
            const updatedEventsList = state.pastEventsList.map((event) =>
              event.fldi_id === id ? { ...event, is_bookmark: true } : event
            );
            setState((prevState) => ({
              ...prevState,
              pastEventsList: updatedEventsList,
            }));
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

 const truncateTitle = (title, wordLimit) => {
    const words = title.split(" ");
    return words.length > wordLimit
      ? `${words.slice(0, wordLimit).join(" ")}...`
      : title;
  };

  const handleRemoveBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    const body = {
      content_id: id,
      content_type: "past_webinar",
      user_id: getID(),
    };
    axios
      .post(apiURL("bookmark/removeBookmark"), body, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedEventsList = state.pastEventsList.map((event) =>
            event.fldi_id === id ? { ...event, is_bookmark: false } : event
          );
          setState((prevState) => ({
            ...prevState,
            pastEventsList: updatedEventsList,
          }));
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePrev = () => {
    setState((prevState) => ({
      ...prevState,
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  const handleNext = () => {
    const { selectedItem, per, pastEventsList } = state;
    const totalPages = Math.ceil(pastEventsList.length / per);
    setState((prevState) => ({
      ...prevState,
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({ ...prevState, open: false }));
  };

  const snackBarClose = () => {
    setState((prevState) => ({ ...prevState, showSnackbar: false }));
  };

  const videoDialogClose = () => {
    setState((prevState) => ({ ...prevState, dialogOpen: false }));
  };

  const { pastEventsList, selectedItem, isMobile, open, showSnackbar, snackBarTimer, severityColor, snackBarMessage, dialogOpen, videoUrl } = state;

  const filteredData = id
    ? pastEventsList.filter((event) => event.fldi_id !== id)
    : pastEventsList;
  const displayData = isMobile
    ? filteredData.slice(selectedItem, selectedItem + 1)
    : filteredData.slice(selectedItem * 2, selectedItem * 2 + 2);

  return (
    <React.Fragment>
      <Meta tableName={"menu"} objectId={11} forPage={"main_page"} />

      <div>
        <section className="otherSection">
          <h2 className="text-center">Other Past Webinars</h2>
          {displayData.length !== 0 && (
            <Container maxWidth="lg" className="container">
              <Carousel
                ref={carouselRef}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={selectedItem}
                onChange={(index) =>
                  setState((prevState) => ({
                    ...prevState,
                    selectedItem: index,
                  }))
                }
              >
                <Row className="otherWeb position-relative">
                  {displayData.map((arrList) => (
                    <Col
                      col="12 col-lg-6 col-xl-4"
                      className="mx-auto custom-col-spacing"
                    >
                      <Card>
                        <Link>
                          <Card.Body
                            onClick={(e) =>
                              handleReadMore(
                                e,
                                arrList.fldi_id,
                                arrList.fldv_title
                                  .replace(/ /g, "-")
                                  .toLowerCase()
                              )
                            }
                          >
                            <div className="position-relative">
                              <Grid container spacing={2}>
                                <Grid item xs={4}>
                                  <div className="Speaker">
                                    <figure>
                                      <div className="d-block imgBox">
                                        <ArticleImageLoader
                                          imageUrl={
                                            arrList.fldv_image_url === ""
                                              ? logoNew
                                              : arrList.fldv_image_url
                                          }
                                          altText={"img"}
                                        />
                                      </div>
                                      <p>{arrList.fldv_speaker_name && truncateTitle(arrList.fldv_speaker_name, 3)}</p>
                                    </figure>
                                  </div>
                                </Grid>
                                <Grid item xs={8}>
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th colSpan="2" className="topic">
                                          {arrList.fldv_title}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <th>Date:</th>
                                        <td>
                                          {Moment(
                                            arrList.fldv_select_date
                                          ).format("ddd, D MMM, YYYY")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Time:</th>
                                        <td>
                                          {Moment(arrList.fldt_time).format(
                                            "LT"
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <th>Duration:</th>
                                        <td>{arrList.fldv_duration} hrs</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div className="action">
                                    <Button
                                      variant="contained"
                                      size="small"
                                      className="readmore"
                                      onClick={(e) =>
                                        handleReadMore(
                                          e,
                                          arrList.fldi_id,
                                          arrList.fldv_title
                                            .replace(/ /g, "-")
                                            .toLowerCase()
                                        )
                                      }
                                    >
                                      Read more
                                    </Button>
                                    {arrList.is_bookmark ? (
                                      <IconButton
                                        style={{ color: "#f28b42" }}
                                        className="bookmark"
                                        onClick={(e) =>
                                          handleRemoveBookMark(
                                            e,
                                            arrList.fldi_id
                                          )
                                        }
                                      >
                                        <Bookmark />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        className="bookmark"
                                        onClick={(e) =>
                                          handleAddBookMark(e, arrList.fldi_id)
                                        }
                                      >
                                        <BookmarkBorder />
                                      </IconButton>
                                    )}
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Card.Body>
                        </Link>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Carousel>
              <IconButton onClick={handlePrev} className="prevButton">
                <ArrowLeftIcon />
              </IconButton>
              <IconButton onClick={handleNext} className="nextButton">
                <ArrowRightIcon />
              </IconButton>
            </Container>
          )}
        </section>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.dialogPaper }}
        >
          <h2 className="text-center  headBoxRegister text-uppercase">
            Share Feedback
          </h2>
          <DialogContent>
            <WebinarForm
              webinarId={state.id}
              title={state.title}
              formClose={handleClose}
            />
          </DialogContent>
        </Dialog>

        <Snackbar
          open={showSnackbar}
          autoHideDuration={snackBarTimer}
          onClose={snackBarClose}
        >
          <Alert
            onClose={snackBarClose}
            severity={severityColor}
            elevation={6}
            variant="filled"
          >
            {snackBarMessage}
          </Alert>
        </Snackbar>

        <Video_dialog
          dialogOpen={dialogOpen}
          videoUrl={videoUrl}
          onClose={videoDialogClose}
        />
      </div>
    </React.Fragment>
  );
};

export default OtherWebinar;