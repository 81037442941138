import React, { Component, createRef } from "react";
//import { Row, Col,  } from 'bootstrap-4-react';
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
//import PasswordField from 'material-ui-password-field';
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { Formik, Form } from "formik";
import * as yup from "yup";

import {
  FormGroup,
  FormControlLabel,
  Snackbar,
  InputAdornment,
  IconButton,
  Grid,
  Typography,
  Avatar,
  InputLabel,
  Select,
  MenuItem,
  NativeSelect,
} from "@material-ui/core";
// import SearchIcon from '@material-ui/icons/Search';
import { apiURL, login, getID } from "../utils";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";
// import { Link, useHistory, withRouter } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import firebase from "../firebase";
import "firebase/messaging";
import Helmet from "react-helmet";
import { Spinner } from "react-bootstrap";
import ForgotPassword from "./ForgotPassword";
import Flag from "../images/flag.png";
const FormControl = require("@material-ui/core/FormControl").default;
const FormHelperText = require("@material-ui/core/FormHelperText").default;
//  const InputLabel     = require('@material-ui/core/InputLabel').default;

const styles = (theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
      width: "100%",
    },
  },
});

const LoginSchema = yup.object().shape({
  number: yup.number().required("This field is required."),
  // login_pin: yup
  //   .array()
  //   .of(yup.string().required("This field is required."))
  //   .min(4, "Please fill all four boxes."),
  //agree: yup.string().required('Please Check, I agree to the Terms & Conditions'),
});

class SignIn extends Component {
  //state = { checked: false }
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      checkBoxError: false,
      checkBoxErrorMsg: "",
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      reload: false,
      isNumberVerified: false,
      isNumberVerifiedErrorMsg: "",
      screenWidth: this.getScreenSize(),
      isSubmitting: false,
    };
    this.securityPinrefs = [createRef(), createRef(), createRef(), createRef()];
    this.handleResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener("resize", this.handleResize);
    //console.log('props '+JSON.stringify(this.props.history));
    //alert('login page'+JSON.stringify(this.props.propsData.history));

    // this.props.propsData.history.push(this.props.propsData.history.location.pathname);
    // alert(this.props.propsData.history.location.pathname)
  }

  insertToken = (token, userId) => {
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      token: token,
      user_id: userId,
    };
    axios
      .post(apiURL("firebase_notification/add"), body, { headers: headers })
      .then((response) => {
        const resultData = response?.data;
        if (resultData?.status === true || resultData?.status === 1) {
          this.setState({ setMeta: resultData?.result });
        }
      });
  };

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.handleResize);
  }
  handleResize() {
    this.setState({
      screenWidth: this.getScreenSize(),
    });
  }
  getScreenSize() {
    const width = window.innerWidth;

    if (width <= 320) {
      return "small";
    } else if (width >= 350 && width <= 400) {
      return "medium";
    } else if (width > 400 && width <= 600) {
      return "medium_one";
    } else if (width > 600 && width <= 950) {
      return "large_one";
    } else {
      return "large";
    }
  }
  handleCheckboxChange = (event) => {
    this.setState({ checked: event.target.checked });
    if (event.target.checked === false) {
      this.setState({
        checkBoxError: true,
        checkBoxErrorMsg: "Please Check, I agree to the Terms & Conditions",
      });
    } else {
      this.setState({
        checkBoxError: false,
        checkBoxErrorMsg: "",
      });
    }
  };
  numberLengthValidate = (e) => {
    if (e.target.value.length !== 10) {
      this.setState({
        isNumberVerified: true,
        isNumberVerifiedErrorMsg: "Mobile number must be exactly 10 digits",
      });
    } else {
      this.setState({ isNumberVerified: false, isNumberVerifiedErrorMsg: "" });
    }
  };
  onSubmit = (values) => {
    console.log('values', values)
    this.setState({ isSubmitting: true });
    const data = {
      mobile: values.number,
      pin: values.login_pin.join(""),
    };
    const headers = {
      "content-type": "application/json",
      Accept: "application/json",
    };
    axios
      .post(apiURL("teacher"), data, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          localStorage.setItem(
            "user_detail",
            JSON.stringify(resultData.result)
          );
          localStorage.setItem("login_status", true);
          if (resultData.result.fldv_email !== "") {
            localStorage.setItem("isEmailVerified", true);
          }
          //alert(JSON.stringify(this.props.propsData));
          //this.props.propsData.history.push('/');
          // const path = this.props.propsData.history.location.pathname;
          // // if (path !== "/sign") {
          // this.props.propsData.history.push("/");

          const redirectAfterLogin = localStorage.getItem('redirectAfterLogin');
          if (redirectAfterLogin) {
            this.props?.propsData?.history?.push(redirectAfterLogin);
            localStorage.removeItem('redirectAfterLogin'); // Clear the saved route
          } else {
            this.props?.propsData?.history?.push('/'); // Default route after login
          }
          this.setState({ isSubmitting: false });

          //   if (this.props.checkJoinNow) {
          //     // this prop send from webinar as per require
          //     this.props.checkJoinNow(true);
          //   }
          // } else {
          //   this.props.propsData.history.push("/");
          // }

          if (firebase.messaging.isSupported()) {
            const messaging = firebase.messaging();

            messaging
              .requestPermission()
              .then(() => {
                return messaging.getToken();
              })
              .then((token) => {
                if (token) {
                  console.log(token);
                  this.insertToken(token, getID());
                }
              })
              .catch((error) => {
                console.log("firebase error:", error);
              });
          } else {
            console.log("firebase snot supported");
          }
          //   } else if (resultData.status === "authrntication_failed") {
          this.setState({
            snackBarMessage: resultData.message,
            showSnackbar: true,
            severityColor: "warning",
          });
        } else {
          this.setState({
            snackBarMessage: resultData.message,
            showSnackbar: true,
            severityColor: "error",
            isSubmitting: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ isSubmitting: false });
      });
  };

  pinChange = (index, setFieldValue) => (e) => {
    const { value } = e.target;
    setFieldValue(`login_pin[${index}]`, value);
    if (value === "") {
      // Move back to the previous input box
      if (index > 0) {
        this.securityPinrefs[index - 1].current.focus();
      }
    } else {
      // Move to the next input box
      if (value.length === 1 && index < 3) {
        this.securityPinrefs[index + 1].current.focus();
      }
    }
  };

  // handleClickShowPassword = (event) => {
  //   this.setState({ showPassword: !this.state.showPassword });
  // };
  handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const re = /^\d+$/;
    if (!re.test(keyValue)) {
      e.preventDefault();
    }
  };
  // handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  //   this.setState({ showPassword: !this.state.showPassword });
  // };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  render() {
    const { classes } = this.props;
    const { screenWidth } = this.state;
    const inputStyles = {
      common: {
        height: "45px",
        fontSize: "35px",
        fontWeight: "400",
        textAlign: "center",
        width: "100%",
        // padding: "20px",
        width: "45px",
        outline: "none"
      },
      small: {
        padding: "5px",
        fontSize: "10px",
        width: "50px",
      },
      medium: {
        padding: "2px 5px",
        fontSize: "35px",
        width: "50px",
      },
      medium_one: {
        boxSizing: `border-box`,
        padding: "2px 5px",
        fontSize: "18px",
        width: "50px",
      },
      large_one: {
        padding: "2px 5px",
        fontSize: "35px",
        width: "50px",
      },
      large: {
        // padding: "30px 30px",
      },
    };
    return (
      <React.Fragment>
        <Helmet>
          <title>SignIn - Existing Users</title>

          <meta charset="utf-8" />
          <link rel="icon" href="/navdisha-favicon.png" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
          <meta name="theme-color" content="#000000" />
          <meta http-equiv="cache-control" content="no-cache" />
          <meta http-equiv="expires" content="0" />
          <meta http-equiv="pragma" content="no-cache" />
          <meta name="robots" content="SignIn - Existing Users" />
          <link rel="canonical" href={window.location.href} />

          <meta name="description" content="SignIn - Existing Users" />
          <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        </Helmet>
        <Formik
          initialValues={{
            number: null,
            login_pin: ["", "", "", ""],
            //agree:"",
          }}
          validationSchema={LoginSchema}
          onSubmit={this.onSubmit}
        >
          {({ errors, handleChange, setFieldValue, touched, values }) => (
            <Form id="login" className={classes.root}>
              <Grid container>
                <Grid className="phone_number" item xs={12} sm={12}>
                  {/* <TextField
                    id="standard-basic"
                    type="text"
                    style={{ marginTop: "40px", marginRight: "12px", width: "100px" }}
                    name="number"
                    disabled
                    value="+91"
                    InputProps={{
                      startAdornment: (
                        <Avatar
                          alt="Flag"
                          src={Flag}
                          style={{ width: "20px", height: "20px", marginRight: "10px" }}
                        />
                      ),
                    }}
                  ></TextField> */}
                  <TextField
                    disabled
                    style={{ backgroundColor: "#F8F9FA", marginTop: "40px", marginRight: "12px", width: "100px", maxHeight: "fit-content", padding: "-2px 0" }}
                    id="select"
                    value={+91}
                    InputProps={{
                      startAdornment: (
                        <Avatar
                          alt="Flag"
                          src={Flag}
                          style={{ width: "20px", height: "20px", marginRight: "4px" }}
                        />
                      ),
                    }}
                    select>
                    <MenuItem value={+91}><h6 className="m-0"><b>+91</b></h6></MenuItem>
                  </TextField>
                  <TextField
                    fullWidth
                    required
                    id="standard-basic"
                    type="tel"
                    className="mt-4"
                    name="number"
                    label="Mobile No."
                    onBlur={(e) => {
                      this.numberLengthValidate(e);
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,10}$/.test(value)) {
                        handleChange(e);
                        setFieldValue("number", e.target.value);
                        this.numberLengthValidate(e)
                      }
                    }}
                    inputProps={{
                      maxLength: 10,
                      pattern: "[0-9]{10}",
                      input: yup.number,
                      inputMode: "numeric",
                    }}
                    value={values.number}
                    error={
                      (errors.number && touched.number) ||
                      this.state.isNumberVerified
                    }
                    helperText={
                      errors.number && touched.number
                        ? errors.number
                        : null || this.state.isNumberVerifiedErrorMsg
                    }
                    onKeyPress={(e) => {
                      // Prevent any non-numeric input
                      if (!/^\d$/.test(e.key)) {
                        e.preventDefault();
                      }
                    }}
                  />
                </Grid>
                {/*<TextField  type="password"className="mt-4" name="password" id='name-label' label="Password" onChange={handleChange} value={this.state.password} error={errors.password && touched.password}  helperText={errors.password && touched.password ? errors.password : null} />  */}

                <Grid item xs={12} sm={12}>
                  <Grid style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <Typography
                      style={{
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        textAlign: "center",
                        fontWeight: "700"
                      }}
                    >
                      Login PIN*
                    </Typography>
                    <ForgotPassword />
                  </Grid>
                  <Grid style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "30px", margin: "20px 0px" }} >
                    {Array.from({ length: 4 }).map((_, index) => (
                      <Grid
                        item
                        xs={1}
                        md={1}
                        lg={1}
                        key={index}
                        style={{
                          width: "100%",
                        }}
                      >
                        <TextField
                          variant="outlined"
                          type="password"
                          inputProps={{
                            maxLength: 1, // Allow only 1 digit per field
                            pattern: "[0-9]", // Only allow 0-9 digits
                            inputMode: "numeric", // Trigger numeric keyboard on mobile
                          }}
                          name={`login_pin[${index}]`} // Pin index for the dynamic form
                          fullWidth={true}
                          onChange={this.pinChange(index, setFieldValue)} // Function to handle changes
                          inputRef={this.securityPinrefs[index]} // Input reference for each pin input
                          value={values.login_pin[index]} // Value for the pin input field
                          onKeyPress={this.handleKeyPress} // Restrict non-numeric input
                          className="inputPin" // Custom class for styling
                          InputProps={{
                            style: {
                              ...inputStyles.common,
                              ...(inputStyles[screenWidth] || {}),
                            },
                          }}
                          required
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                {/*W<Grid item xs={12} sm={12}>  
                      <FormControl required error={errors.agree && touched.agree || this.state.checkBoxError} component="fieldset" className="mt-4" size="small"> 
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox checked={this.state.checked} onClick={this.handleCheckboxChange} onKeyUp={handleChange} onKeyDown={handleChange} onChange={handleChange} name="agree" />}
                            label="I agree to the Terms & Conditions"
                          />  
                        </FormGroup>
                        <FormHelperText> {errors.agree && touched.agree ? errors.agree : null || (this.state.checkBoxError)?this.state.checkBoxErrorMsg:""}</FormHelperText>
                      </FormControl>
                  </Grid>*/}
                <Grid item xs={12} sm={12}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className="mt-2 text-capitalize"
                    style={!(values.login_pin[0] != "" && values.login_pin[1] != "" && values.login_pin[2] != "" && values.login_pin[3] != "" && values.number?.length === 10) ? {
                      opacity: "0.5",
                      color: "lightgrey",
                      cursor: "not-allowed"
                    } : null}
                    disabled={this.state.isSubmitting || !(values.login_pin[0] != "" && values.login_pin[1] != "" && values.login_pin[2] != "" && values.login_pin[3] != "" && values.number?.length === 10)}
                  >
                    {this.state.isSubmitting ? (
                      <Spinner variant="light" />
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                  <Typography
                    style={{
                      marginTop: "1rem",
                      textAlign: "center",
                    }}>Are you a new user?
                  </Typography>
                  <Button
                    type="button"
                    onClick={(e) =>
                      this.props.handleSignup(e)
                    }
                    fullWidth
                    variant="outlined"
                    color="primary"
                    className="mt-3 text-capitalize"
                    disabled={this.state.isSubmitting}
                  >
                    Sign Up
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={this.state.showSnackbar === true}
          autoHideDuration={6000}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment >
    );
  }
}

export default withStyles(styles)(SignIn);
