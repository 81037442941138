import React, { Component } from "react";
import { Row, Col, Card } from "bootstrap-4-react";

import "../../style/BookMark.scss";
import { withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { IconButton, Container } from "@material-ui/core";

import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { apiURL, getID, isLogin } from "../../utils";
import axios from "axios";
import Video_dialog from "../../Video_dialog";

import { BookmarkBorder, PlayArrow, ArrowDropDown } from "@material-ui/icons";
import { Carousel } from "react-responsive-carousel";
import Moment from "moment";
import { Bookmark } from "@material-ui/icons";
import Rating from "../../components/Rating";
import ShareNews from "../../govt-circular-gr/ShareNews";
import { FaLanguage } from "@react-icons/all-files/fa/FaLanguage";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
const styles = (theme) => ({
  formControl: {
    minWidth: 200,
    marginRight: theme.spacing(2),
    position: "relative",
    display: "flex",
    alignItems: "center",
  },
  select: {
    width: "100%",
    padding: "10px 30px 10px 10px",
    borderColor: "#064e89",
    borderWidth: "2px",
    borderRadius: "4px",
    appearance: "none",
    backgroundColor: "white",
    fontSize: "16px",
    fontWeight: "bold",
    color: "#064e89",
  },
  icon: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none",
    color: "#064e89",
  },
  label: {
    position: "absolute",
    left: "10px",
    top: "50%",
    transform: "translateY(-50%)",
    backgroundColor: "white",
    padding: "0 5px",
    color: "#064e89",
    fontWeight: "bold",
    transition: "0.2s",
    pointerEvents: "none",
    transformOrigin: "top left",
    "&.float": {
      top: "-10px",
      transform: "scale(0.75)",
    },
  },
  filter: {
    position: "absolute",
    textAlign: "center",
    display: "block",
    marginTop: "2rem",
    width: "100%",
    fontSize: "1rem",
  },
  filter_group_year: {
    marginRight: "1rem",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.5rem",
      marginRight: "2rem",
      fontSize: "0.8rem",
    },
  },
  filter_group_lan: {
    marginLeft: "1rem",
    [theme.breakpoints.down("sm")]: {
      marginRight: "1rem",
      fontSize: "0.8rem",
    },
  },
  dialogPaper: {
    // minHeight: '90vh',
    // maxHeight: '90vh',
    borderRadius: "0px",
    maxWidth: "48.75rem",
    width: "60rem",
    //  height:'100%',
    // padding:'0rem',
  },

  dialog: {
    padding: "0rem",
  },

  paper: {
    padding: theme.spacing(0),
    textAlign: "justify",
    color: theme.palette.text.secondary,
    borderRadius: "0px",
    boxShadow: "0px 4px 10px -1px rgba(0,0,0,0.1)",
    height: "100%",
  },
});

class VideoGlimses extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      videoUrl: "",
      open: false,
      dialogOpen: false,
      isOpen: false,

      selectedTab: 0,
      showComponent: false,
      showRating: false,

      page: 1,
      per: window.innerWidth <= 1000 ? 1 : 2,
      loading: false,
      videos: this.props.videos,
      selectedItem: 0,
      isMobile: false,
    };

    this.openModal = this.openModal.bind(this);

    this.clickCategory = this.clickCategory.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ isMobile: window.innerWidth <= 1000 });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.videos !== this.props.videos) {
      this.setState({ videos: this.props.videos });
    }
  }
  handleRemoveBookMark = (e, id) => {
    e.preventDefault();
    e.stopPropagation();
    const { selectedItem, per } = this.state;
    const { videos, handleRemoveVideoBookmark } = this.props;

    this.props.handleRemoveVideoBookmark(id);
    const updatedEvents = videos.filter((event) => event.video_id !== id);

    const totalPages = Math.ceil(updatedEvents.length / per);
    console.log(updatedEvents);
    if (selectedItem >= totalPages && totalPages > 0) {
      this.setState({ selectedItem: totalPages - 1 });
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
  }

  handleRating = (videoId, rating) => {
    if (!isLogin()) {
      this.setState({ showComponent: true });
    } else {
      this.setState({ showRating: true, videoId, videoId, rating: rating });
    }
  };

  handleCloseRating = () => {
    this.setState({ showRating: false });
  };
  clickCategory = (e, id) => {
    //alert(id)
    this.setState(
      {
        categoryID: id,
        selectedTab: id,
      },
      () => {
        this.videoListData();
      }
    );
  };

  handleClickOpen = (e, videoUrl, id) => {
    console.log(videoUrl);
    if (!isLogin()) {
      this.setState({ showComponent: true });
    }
    // else{
    this.setState({ open: true, videoUrl: videoUrl });

    //}
  };

  handleClose = () => {
    //this.setState({ open: false, videoUrl:'' })
    this.setState({
      open: false,
      videoUrl: "",
    });
  };

  openModal() {
    this.setState({ isOpen: true });
  }

  handlePrev = () => {
    this.setState((prevState) => ({
      selectedItem: Math.max(prevState.selectedItem - 1, 0),
    }));
  };

  handleNext = () => {
    const { selectedItem, per } = this.state;
    const totalPages = Math.ceil(this.props.videos.length / per);
    console.log(this.props.videos.length);
    this.setState((prevState) => ({
      selectedItem: Math.min(prevState.selectedItem + 1, totalPages - 1),
    }));
  };
  render() {
    const { classes, videos } = this.props;
    const { selectedItem, per, isMobile } = this.state;

    const displayData = isMobile
      ? videos.slice(selectedItem, selectedItem + 1)
      : videos.slice(selectedItem * 2, selectedItem * 2 + 2);
    const singleData = displayData.length === 1;

    return (
      <React.Fragment>
        <div>
          <section className="videoBookmarkSection">
            <Container maxWidth="lg" className="container">
              <h2>Videos</h2>
              <Carousel
                ref={this.carouselRef}
                showThumbs={false}
                infiniteLoop={true}
                autoPlay={false}
                showIndicators={false}
                showStatus={false}
                selectedItem={selectedItem}
                onChange={(index) => this.setState({ selectedItem: index })}
              >
                <Row className="videoBook position-relative d-flex">
                  {displayData.map((arrList, index) => {
                    return (
                      <Col
                        col="12 col-lg-6 col-xl-4"
                        className="mx-auto custom-col-spacing"
                      >
                        <Card>
                          {/* <Card.Body> */}
                          <div className="position-relative">
                            <div className="Speaker">
                              <figure
                                onClick={(e) =>
                                  this.handleClickOpen(
                                    e,
                                    arrList.video_url,
                                    arrList.video_id
                                  )
                                }
                                style={{ cursor: "pointer" }}
                              >
                                <div className="d-block imgBox">
                                  <img
                                    src={arrList.video_image}
                                    className="img-fluid"
                                    alt="img"
                                  />
                                </div>
                                {/* <p>{arrList.video_speaker_name}</p> */}
                              </figure>
                              <div className="playButton">
                                <IconButton
                                  className="playButtonIcon"
                                  onClick={(e) =>
                                    this.handleClickOpen(
                                      e,
                                      arrList.video_url,
                                      arrList.video_id
                                    )
                                  }
                                >
                                  <PlayArrow />
                                </IconButton>
                              </div>
                              <div className="video_bookmark_topic">{arrList.video_title}</div>
                              <div
                                className="video_bookmark_action"
                              ><div style={{ display: "flex" }}>

                                  <IconButton
                                    style={{ color: "orange" }}
                                    className="bookmark"
                                    onClick={(e) =>
                                      this.handleRemoveBookMark(
                                        e,
                                        arrList.video_id
                                      )
                                    }
                                  >
                                    <Bookmark />
                                  </IconButton>

                                  <div
                                    className="rating text-center"
                                    style={{ border: "2px solid black" }}
                                  >
                                    <Rating
                                      videoId={arrList.video_id}
                                      userId={getID()}
                                      rating={arrList.rating}
                                      color="gold"
                                    />
                                  </div>
                                </div>
                                <ShareNews
                                  webinarId={arrList.video_id}
                                  shareUrl={
                                    arrList.video_url === ""
                                      ? "https://navdisha.navneet.com"
                                      : arrList.video_url
                                  }
                                  title={arrList.video_title}
                                  date={Moment(
                                    arrList.video_select_date
                                  ).format("dddd, D MMM, YYYY")}
                                  time={Moment(arrList.fldt_time).format(
                                    "hh:mmA"
                                  )}
                                />
                              </div>

                            </div>

                            {/* </div> */}
                          </div>
                          {/* </Card.Body> */}
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Carousel>
              <IconButton
                onClick={this.handlePrev}
                className={singleData ? "singlePrevButton" : "prevButton"}
              >
                <ArrowLeftIcon />
              </IconButton>
              <IconButton
                onClick={this.handleNext}
                className={singleData ? "singleNextButton" : "nextButton"}
              >
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </section>

          <Video_dialog
            dialogOpen={this.state.open}
            videoUrl={this.state.videoUrl}
            onClose={(e) => this.handleClose(e)}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(VideoGlimses);
