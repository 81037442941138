import React, { useState } from 'react';
import "../style/Offline.scss";
import WifiBG from "../images/wifi-bg.png";
import Wifi from "../images/wifi.png";
import Retry from "../images/retry.png";
import { Button } from 'bootstrap-4-react/lib/components';

const Offline = ({ onRetry }) => {
    const [loading, setLoading] = useState(false);

    const handleRetry = () => {
        setLoading(true);

        setTimeout(() => {
            if (navigator.onLine) {
                setLoading(false);
                onRetry();
            } else {
                setLoading(false);
            }
        }, 500);
    };

    return (
        <div>
            {loading ?
                <div className="loading-spinner"></div>
                :
                <div className='offline-screen'>
                    <div className='offline-content'>
                        <img src={Wifi} alt="offline" />
                        <div className='offline-text'>You're offline</div>
                        <span>Please connect to the internet and try again.</span>
                        <div>
                            <Button onClick={() => handleRetry()} disabled={loading}>
                                <img className='icon-retry' src={Retry} alt="retry" />
                                {loading ? "Checking..." : "Retry"}
                            </Button>
                        </div>
                    </div>
                    {!loading && <img className='wifi-bg' src={WifiBG} alt="offline" />}
                </div>
            }
        </div>
    );
};

export default Offline;
