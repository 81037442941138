import React, { createContext, useContext, useEffect, useState } from 'react';

export const PWAContext = createContext({ isPWA: false });

export const usePWA = () => {
    const context = useContext(PWAContext);
    if (!context) {
        throw new Error('usePWA must be used within a PWAProvider');
    }
    return context;
};

export const PWAProvider = ({ children }) => {
    const [isPWA, setIsPWA] = useState(false);

    useEffect(() => {
        const checkIsPWA = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const pwa = urlParams.get("PWA");
            if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone || pwa) {
                setIsPWA(true)
            } else {
                setIsPWA(false)
            }
        }
        checkIsPWA()
    }, []);

    return <PWAContext.Provider value={{ isPWA }}>{children}</PWAContext.Provider>;
};
