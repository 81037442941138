import React, { useState, useEffect } from "react";
import Appbar from "../components/Appbar";
import ArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import ArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
//import {Paper} from '@material-ui/core';
//import Button from '@material-ui/core/Button';
import Carouselslide from "../home/Carouselslide";
import Navmenu from "../components/Navmenu";
import Footer from "../components/Footer";
import Student from "../home/Student";
import Welcome from "../home/Welcome";
import { Container, Row, Col, Card } from "bootstrap-4-react";
import "../style/Home.scss";
import Bootstrap, { Carousel } from "bootstrap-4-react";
import axios from "axios";
import { apiURL } from "../utils";
import SanitizedHTML from "react-sanitized-html";
import Meta from "../meta/Meta";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Helmet } from "react-helmet";

import HomeEvents from "./HomeEvents";
import WhatsNew from "./WhatsNew";
import ArticleImageLoader from "../components/Article/ArticleImageLoader";

const Home = (props) => {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severityColor, setSeverityColor] = useState("");
  const [snackBarTimer, setSnackBarTimer] = useState("");

  const navMenuRef = React.createRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    getTestimonialsData();

    return () => {
      // Cleanup function (equivalent to componentWillUnmount)
    };
  }, []);

  useEffect(() => {
    if (navMenuRef.current) {
      navMenuRef.current.getMenus();
    }
  }, []);

  const snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowSnackbar(false);
  };

  const getTestimonialsData = () => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .post(apiURL("testimonials/get_testimonials"), { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setTestimonialsData(resultData.result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*authenticateEmail = (email) => {
    const headers = { 
    'Content-type': 'application/json'
    }
    const body = { 
        "email": email, 
    }
    axios.post(apiURL('authenticate'), body, {headers: headers})
    .then(response => { 
        const resultData = response.data; 
        if(resultData.status === true || resultData.status === 1)
        { 
          setSnackBarMessage("Email address verified successfully. Please login");
          setShowSnackbar(true);
          setSeverityColor('success');
          setSnackBarTimer(3000);
          props.history.push({ pathname: '/sign',  state: { 
            value: 0,
            snackBarMessage: "Email address verified successfully. Please login",
            showSnackbar: true , 
            severityColor:'success', 
            snackBarTimer: 3000
          } })
        } 
    })
   }*/

  return (
    <React.Fragment>
      <Meta tableName={"menu"} objectId={1} forPage={"main_page"} />
      <Helmet>
        <title>Home</title>
        <meta charset="utf-8" />
        <link rel="icon" href="/navdisha-favicon.png" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
        <meta name="theme-color" content="#000000" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta name="robots" content="Home" />
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content="Home" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" />
      </Helmet>
      <Appbar />
      <div className="sticky-top sign-light " >
        <Navmenu ref={navMenuRef} />
      </div>
      <div className="top-110px">
        <Carouselslide propsData={props} />
      </div>
      {/* <Navmenu />  */}

      <div className="WelcomeBox d-none">
        <Welcome propsData={props} />
      </div>
      <div className="clearfix"></div>

      <Student />

      {/* <section className="videoBox mt-md-5 mt-4">
        <Video />
      </section> */}
      <HomeEvents history={props.history} />
      <WhatsNew history={props.history} />
      {/* <PastWebinarHome /> 
      {/**/}

      {testimonialsData.length !== 0 && (
        <section>
          <Container>
            <Row className="Testimonial mt-5">
              <Col col="sm-12" className="text-center">
                <h2 className="mb-0">Testimonials</h2>
              </Col>

              <Col col="lg-5 md-8 sm-10 " className="mx-auto TestBox">
                <Carousel w="100" id="carouselExampleControls">
                  <Carousel.Inner>
                    {testimonialsData.map((arrList, index) => (
                      <Carousel.Item active={index === 0} key={index}>
                        <Card>
                          <ArticleImageLoader imageUrl={arrList.fldv_image_url}
                            altText={arrList.fldv_name} className="w-25 mx-auto" />

                          <Card.Body className="text-center">
                            <Card.Title>{arrList.fldv_name}</Card.Title>
                            {/* <Card.Text> */}
                            <SanitizedHTML html={arrList.fldt_description} />
                            {/* </Card.Text> */}
                          </Card.Body>
                        </Card>
                      </Carousel.Item>
                    ))}
                    {/* ... (existing commented out carousel items) ... */}
                  </Carousel.Inner>
                  <Carousel.Prev href="#carouselExampleControls">
                    <ArrowLeftIcon className="ArrowLeft" />
                  </Carousel.Prev>
                  <Carousel.Next href="#carouselExampleControls">
                    <ArrowRightIcon className="ArrowRight" />
                  </Carousel.Next>
                </Carousel>
              </Col>
            </Row>
            {/*{passwordStatus() === "1"?<ResetPassword />:""}*/}
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={showSnackbar === true}
              autoHideDuration={snackBarTimer}
              onClose={snackBarClose}
            >
              <Alert
                onClose={snackBarClose}
                severity={severityColor}
                elevation={6}
                variant="filled"
              >
                {snackBarMessage}
              </Alert>
            </Snackbar>
          </Container>
        </section>
      )}
      <Footer />
    </React.Fragment>
  );
};

/*function Item(props)
{
    return (
        <Paper>   
           
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>
 
            <Button className="CheckButton">
                Check it out!
            </Button>
        </Paper>
    )
}*/

export default Home;
