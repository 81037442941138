import React, { Component } from 'react';
class Banner extends Component {
   render() {
      return (
         <React.Fragment>
            <section className="BannerBox" style={window.innerWidth < 600 ? { marginTop: "74px" } : { marginTop: "80px" }}>
               <div className="sixteen-nine">
                  <div className="content text-center text-white"  >
                     <h2 style={{ visibilty: "hidden", color: "transparent" }}>About Us</h2>
                     <p style={{ visibilty: "hidden", color: "transparent" }}>Lorem ipsume</p>
                  </div>
               </div>
            </section>
         </React.Fragment>
      );
   }
}
export default Banner;