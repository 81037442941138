import React, { useEffect, useState } from 'react';
import Appbar from "../Appbar";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { allowedMenuNames } from '../../common/pwaNavbarMainMenu';
import { Grid } from '@material-ui/core';
import trainingsIcon from "../../images/Icon_Trainings.png"
import "../../style/PwaNavMenu.scss"
import { useHistory } from 'react-router-dom';
import trforTeacherIcon from "../../images/Icon_TR.png"
import aboutUsIcon from "../../images/Icon_About Us.png"
import faqIcon from "../../images/Icon_FAQ.png"
import contactusIcon from "../../images/Icon-contact-us.png"
import { useLocation } from 'react-router-dom';
import successStories from "../../images/Icon_Success stories .png"
import govtCirculars from "../../images/Icon_Govt circular GR.png"
import newsIcon from "../../images/Icon_News.png"
import interviewIcon from "../../images/Icon_Inteviews.png"
import BookRecommendationsIcon from "../../images/Icon_Books Recommendations.png";
import blogsIcon from "../../images/Icon_Blogs.png"
import academicEssayIcon from "../../images/Icon_Academic Essays.png"
import selfDevelopmentIcon from "../../images/Icon_Self Development.png"
import pastTrainingIcon from "../../images/Icon_Past Training.png"
import upcomingEventIcon from "../../images/Icon_Upcoming Events.png"
import overviewIcon from "../../images/Icon_OverView.png"
import webinarIcon from "../../images/Icon_Webinar.png"
import videoIcon from "../../images/Icon_Videos.png"

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: theme.spacing(16),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
  },
}));

export const PwaNavigation = () => {
  const storedMenus = localStorage.getItem("menuData");
  const [menus, setMenus] = useState(storedMenus ? JSON.parse(storedMenus) : initialMenus);
  const [subMenu, setSubMenu] = useState([])
  const history = useHistory();
  const location = useLocation()
  const classes = useStyles();

  useEffect(() => {
    if (location?.state?.menudata === "more") {
      setSubMenu([])
    }
    const parsedMenus = storedMenus ? JSON.parse(storedMenus) : [];
    if (location?.state?.menudata) {
      const selectedMenu = parsedMenus.find(menu =>
        menu.fldv_name.toLowerCase() === location.state.menudata.toLowerCase()
      );
      if (selectedMenu) {
        setSubMenu(selectedMenu.arrChilds || []);
      }
    }
  }, [location?.state?.menudata]);


  const getMenuIcon = (menuName) => {
    switch (menuName.toLowerCase()) {
      case "about us":
        return <img src={aboutUsIcon} alt="aboutUsIcon" className='pwa-nav-icon-image' />;
      case "faq":
        return <img src={faqIcon} alt="faqIcon" className='pwa-nav-icon-image' />;
      case "contact us":
        return <img src={contactusIcon} alt="contactusIcon" className='pwa-nav-icon-image' />;
      case "tr. for teacher":
        return <img src={trforTeacherIcon} alt="trforTeacherIcon" className='pwa-nav-icon-image' />;
      case "overview":
        return <img src={overviewIcon} alt="overviewIcon" className="pwa-nav-icon-image" />;
      case "upcoming events":
        return <img src={upcomingEventIcon} alt="upcomingEventIcon" className="pwa-nav-icon-image" />;
      case "past trainings":
        return <img src={pastTrainingIcon} alt="pastTrainingIcon" className="pwa-nav-icon-image" />;
      case "past webinars":
        return <img src={webinarIcon} alt="webinarIcon" className="pwa-nav-icon-image" />;
      case "videos":
        return <img src={videoIcon} alt="videoIcon" className="pwa-nav-icon-image" />;
      case "blogs":
        return <img src={blogsIcon} alt="blogsIcon" className="pwa-nav-icon-image" />;
      case "academic essays":
        return <img src={academicEssayIcon} alt="academicEssayIcon" className="pwa-nav-icon-image" />;
      case "success stories":
        return <img src={successStories} alt="successStories" className="pwa-nav-icon-image" />;
      case "interviews":
        return <img src={interviewIcon} alt="interviewIcon" className="pwa-nav-icon-image" />;
      case "book recommendations":
        return <img src={BookRecommendationsIcon} alt="BookRecommendationsIcon" className="pwa-nav-icon-image" />;
      case "self development":
        return <img src={selfDevelopmentIcon} alt="selfDevelopmentIcon" className="pwa-nav-icon-image" />;
      case "news":
        return <img src={newsIcon} alt="newsIcon" className="pwa-nav-icon-image" />;
      case "mh state board":
        return <img src={govtCirculars} alt="govtCirculars" className="pwa-nav-icon-image" />;
      case "central board":
        return <img src={govtCirculars} alt="govtCirculars" className="pwa-nav-icon-image" />;
      default:
        return <img src={trainingsIcon} alt="articles" className='pwa-nav-icon-image'/>;
    }
  };

  const handleMoreClick = (path) => {
    history.push(path);
  }

  return (
    <>
      <Appbar />
      {subMenu.length > 0 ?
        <Grid container spacing={2} style={{ width: '100%', margin: '80px 0px 0px 0px' }}>
          {subMenu
            .map((menu) => (
              <Grid item xs={6} sm={6} md={6} key={menu.fldi_id} onClick={() => handleMoreClick(menu.fldv_to_path)}>
                <Paper className={classes.paper} elevation={3}>
                  {getMenuIcon(menu.fldv_name)}
                  <Typography variant="h6" className='pwa-nav-text-main'>{menu.fldv_name}</Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
        :
        <Grid container spacing={2} style={{ width: '100%', margin: '80px 0px 0px 0px' }}>
          {menus
            .filter((menu) => !allowedMenuNames.includes(menu.fldv_name))
            .map((menu) => (
              <Grid item xs={6} sm={6} md={6} key={menu.fldi_id} onClick={() => handleMoreClick(menu.fldv_to_path)}>
                <Paper className={classes.paper} elevation={3}>
                  {getMenuIcon(menu.fldv_name)}
                  <Typography variant="h6" className='pwa-nav-text-main'>{menu.fldv_name}</Typography>
                </Paper>
              </Grid>
            ))}
        </Grid>
      }
    </>
  );
};
